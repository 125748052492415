angular.module 'nn.page-info.services', []

.factory 'SharedPages', (AdonisService, $q) ->
    pages = {}

    isLeftOrRight = (page) ->
      if page % 2 is 0 then 'Left' else 'Right'

    filterPages = (id, items, curPageHash, sharedPages, curPageNum)->
      items
        .filter (page) ->
          isShared =  sharedPages.filter (item) -> item.folioNumber is page.folioNumber and item.spread.id is page.spread.id
          (page.pageHash is curPageHash and !isShared.length)
        .map (page) ->
          displayText =  page.title or page.sectionName
          spread: page.spread
          stories: page.stories
          displayText: if displayText then " - #{displayText}"
          folioNumber: page.folioNumber
          book: page.bookName

    get: (id, sharedPages, curPageHash, sharePage, curPageNum, view='issue-pagination') =>
      deferred = $q.defer()

      if pages[id]
        deferred.resolve filterPages(id, pages[id], curPageHash, sharedPages, curPageNum)
      else
        AdonisService.query(view, id).then (data) =>
          pages[id] = data
          deferred.resolve filterPages(id, data, curPageHash, sharedPages, curPageNum)

      deferred.promise

    destroy: ->
      Object.keys(pages).forEach (page) ->
        delete pages[page]

    create: (destPage, destSpreadId, srcPageLayout) ->
      AdonisService["assign#{ isLeftOrRight(destPage) }PageLayout"] destSpreadId, srcPageLayout

    remove: (page, spreadId) ->
      AdonisService["restore#{ isLeftOrRight(page) }PageLayout"] spreadId

.factory 'FeatureAccess', ($http, $q, $stateParams, AdonisData, HOSTS, User, ServicesAvailability, ProfileSettings) ->

    _selectedOrganization = null
    _data =
      dinkus: true,
      socialMedia: false,
      ideas: true
      hasUtilityOrderingAccess: null
      hasDigitalPackageAccess: null
      hasOvuVideoAccess: null,
      hasChartbeatFeaturesAccess: null,
      hasBreakingNewsAccess: null,
      hasSiteBuilderAccess: null # The user has access to at least one siteBuilder feature
      hasNewslistAccess: null # the user has access to at least one Newslist feature
      siteBuilderOrgs: null # list of siteBuilder the user has access to
      newslistOrgs: null # list of newslist site the user has access to
      loading: false
      organizations: []
      pageBuilderUtilityOrdering: false
      pageBuilderNewslist: false # When the user has access to a page builder, and selects a publication, it is set to true if this publication belongs to an organization which has a newslist enable feature_tags
      newslistOrganizations:
        loading: false,
        defer: null,
        organizations: null,

    featureTagsIncludes = (feature_tags, feature) ->
      feature_tags && feature_tags.length > 0 && feature_tags.includes(feature)


    hasDigitalPackageAccess: (orgId) ->
      deferred = $q.defer()
      $http
        method: 'GET'
        url: "#{HOSTS.monza}/orgs/api/v2/user/self/?feature_tags=digital-package"
        cache: false
        auth: true
      .success (results) =>
          _data.hasDigitalPackageAccess = results.organizations.filter (row) ->
            if row.id.toString() is orgId or row.id is orgId
              featureTagsIncludes(row.feature_tags, 'digital-package')
          deferred.resolve(_data.hasDigitalPackageAccess.length > 0)
      .error (error) =>
          deferred.reject(error)
      deferred.promise
    
    hasBreakingNewsAccess: (orgId) ->
      deferred = $q.defer()
      $http
        method: 'GET'
        url: "#{HOSTS.monza}/orgs/api/v2/user/self/?feature_tags=breaking-news"
        cache: false
        auth: true
      .success (results) =>
        _data.hasBreakingNewsAccess = results.organizations.filter (row) -> 
          if row.id.toString() is orgId
            featureTagsIncludes(row.feature_tags, 'breaking-news')
        deferred.resolve(_data.hasBreakingNewsAccess.length > 0)
      .error (error) =>
        deferred.reject(error)
      deferred.promise

    hasSocialEngagementAccess: (orgId) ->
      deferred = $q.defer()
      $http
        method: 'GET'
        url: "#{HOSTS.monza}/orgs/api/v2/user/self/?feature_tags=social-media-engagement"
        cache: false
        auth: true
      .success (results) =>
        _data.hasSocialEngagementAccess = results.organizations.filter (row) -> 
          if row.id.toString() is orgId
            featureTagsIncludes(row.feature_tags, 'social-media-engagement')
        deferred.resolve(_data.hasSocialEngagementAccess.length > 0)
      .error (error) =>
        deferred.reject(error)
      deferred.promise

    hasOvuVideoAccess: (orgId) ->
      deferred = $q.defer()
      $http
        method: 'GET'
        url: "#{HOSTS.monza}/orgs/api/v2/user/self/?feature_tags=oovvuu-video"
        cache: false
        auth: true
      .success (results) =>
        _data.hasOvuVideoAccess = results.organizations.filter (row) -> 
          if row.id.toString() is orgId
            featureTagsIncludes(row.feature_tags, 'oovvuu-video')
        deferred.resolve(_data.hasOvuVideoAccess.length > 0)
      .error (error) =>
        deferred.reject(error)
      deferred.promise

    hasChartbeatFeaturesAccess: (orgId, tag) ->
      deferred = $q.defer()
      $http
        method: 'GET'
        url: "#{HOSTS.monza}/orgs/api/v2/user/self/?feature_tags=#{tag}"
        cache: false
        auth: true
      .success (results) =>
        _data.hasChartbeatFeaturesAccess = results.organizations.filter (row) -> 
          if row.id.toString() is orgId
            featureTagsIncludes(row.feature_tags, tag)
        deferred.resolve(_data.hasChartbeatFeaturesAccess.length > 0)
      .error (error) =>
        deferred.reject(error)
      deferred.promise

    hasUtilityOrderingAccess: () ->
      deferred = $q.defer()
      if _data.hasUtilityOrderingAccess != null
        deferred.resolve(_data.hasUtilityOrderingAccess)
      else
        $http
          method: 'GET'
          url: "#{HOSTS.monza}/orgs/api/v2/user/self/?feature_tags=utility_ordering"
          cache: false
          auth: true
        .success (results) =>
          _data.utilityOrderingOrgs = results.organizations.filter (row) -> featureTagsIncludes(row.feature_tags, 'utility_ordering')
          _data.hasUtilityOrderingAccess = _data.utilityOrderingOrgs.length > 0
          deferred.resolve(_data.hasUtilityOrderingAccess)
        .error (error) =>
          deferred.reject(error)

      deferred.promise

    hasSiteBuilderAccess: () ->
      deferred = $q.defer()
      if _data.hasSiteBuilderAccess != null
        deferred.resolve(_data.hasSiteBuilderAccess)
      else
        User.getSites(view="newsnow")
          .then (sites) ->
            deferred.resolve(sites.length > 0)
          .catch (e) ->
            if e is ServicesAvailability.data.ServicesNotAvailable
              deferred.resolve(e)
            deferred.resolve(false)

      return deferred.promise

    hasSilverstoneAvailability: () ->
      PROFILE_API_URL = ProfileSettings.API_URL
      id = 'self'
      deferred = $q.defer()
      $http
        method: 'GET'
        url: "#{PROFILE_API_URL}#{id}/"
        auth: true
        cache: false
      .success (response) ->
        deferred.resolve ServicesAvailability.data.silverstone
      .error (error) ->
        deferred.resolve ServicesAvailability.data.ServicesNotAvailable

      return deferred.promise

    hasNewsListAccess: () ->
      deferred = $q.defer()
      if _data.hasNewslistAccess != null
        deferred.resolve(_data.hasNewslistAccess)
      else
        $http
          method: 'GET'
          url: "#{HOSTS.monza}/orgs/api/v2/user/self/?feature_tags=newslist2"
          cache: false
          auth: true
        .success (results) =>
          _data.newslistOrgs = results.organizations.filter (row) -> featureTagsIncludes(row.feature_tags, 'newslist2')
          _data.hasNewslistAccess = _data.newslistOrgs.length > 0
          deferred.resolve(_data.hasNewslistAccess)
        .error (e) =>
          _data.hasNewslistAccess = false
          deferred.reject(e)
      return deferred.promise

    filterOrgByFeatures: (organizations, features) ->
      if features.length == 0
        return organizations
      organizations.filter (organization) ->
        commonFeatures = organization.feature_tags.filter (tag) ->
          features.includes tag
        commonFeatures.length == features.length

    loadOrganizations: (features = []) ->
      if _data.newslistOrganizations.loading
        return _data.newslistOrganizations.defer.promise
      else
        if _data.newslistOrganizations.organizations != null
          deferred  = $q.defer()
          deferred.resolve @filterOrgByFeatures(_data.newslistOrganizations.organizations , features)
          return deferred.promise
        else
          _data.newslistOrganizations.defer = $q.defer()
          _data.newslistOrganizations.loading = true
          User.getOrganizations().then (organizations) =>
            _data.newslistOrganizations.organizations = organizations
            _data.newslistOrganizations.loading = false
            _data.newslistOrganizations.defer.resolve @filterOrgByFeatures(organizations , features)
          return _data.newslistOrganizations.defer.promise

    fetchUserOrganizations: () ->
      deferred = $q.defer()
      if _data.organizations.length > 0
        deferred.resolve _data.organizations
      else
        $http
          method: 'GET'
          url: "#{HOSTS.monza}/orgs/api/v2/user/self/"
          cache: false
          auth: true
        .success (orgs) =>
          _data.organizations = orgs.organizations
          deferred.resolve(_data.organizations)
        .error (e) =>
          _data.organizations = []
          deferred.reject(e)
      return deferred.promise

    setCurrentOrganization: (orgCode) ->
      deferred = $q.defer()
      if orgCode == _selectedOrganization && !_data.loading
        deferred.resolve("newslist": _data.pageBuilderNewslist, "utilityOrdering": _data.pageBuilderUtilityOrdering)
      else
        _selectedOrganization = orgCode
        _data.loading = true
        AdonisData.getPublicationPresenter(orgCode).then (res) =>
          orgList = res.organisations
          @fetchUserOrganizations().then (orgs) ->
            _data.pageBuilderUtilityOrdering = orgs.reduce((acc, organization) ->
              acc || orgList.reduce( (acc2, organizationID) ->
                acc2 || (organization.id == organizationID && featureTagsIncludes(organization.feature_tags, 'utility_ordering'))
              , false)
            , false)
            _data.pageBuilderNewslist = orgs.reduce((acc, organization) ->
              acc || orgList.reduce( (acc2, organizationID) ->
                acc2 || (organization.id == organizationID && featureTagsIncludes(organization.feature_tags, 'newslist2'))
              , false)
            , false) # default to False
            _data.loading = false
            deferred.resolve ("newslist": _data.pageBuilderNewslist, "utilityOrdering": _data.pageBuilderUtilityOrdering)
      deferred.promise

    hasAccess: () ->
      deferred = $q.defer()
      requests = []
      requests.push @hasSiteBuilderAccess()
      requests.push @hasNewsListAccess()
      requests.push @hasSilverstoneAvailability()
      requests.push @hasUtilityOrderingAccess()
    
      $q.all(requests).then ([siteBuilder, newsList, silverstone]) ->
        deferred.resolve
          siteBuilder: siteBuilder
          newsList: newsList
          silverstone: silverstone
      return deferred.promise

.service 'Runsheet', ($http, $q, Auth, HOSTS, AdonisData, AdonisService, NEWSLIST_STORY_TYPE, NEWSLIST_DATE_RANGE_TODAY, NEWSLIST_SECTION) ->
    API_URL = "#{HOSTS.newslist2}/graphql"
    ORG_LOOKUP_URL = "#{HOSTS.monza}/api/v2/organization/"
    PRESENTER = 'StoryRenderLookupPresenter'

    CREATE_COPYFIT_TASK_MUTATION_QUERY = """
      mutation CreateCopyfitTaskMutation($input: CreateCopyfitTaskInput!) {
        createCopyfitTask(input: $input) {
          clientMutationId

          runsheetIdea {
            node {
              alternativeTaskId
            }
          }
        }
      }
    """

    RUNSHEET_QUERY = (pageSize) ->
      ("""
          query GetRunsheet($organisation: ID!, $dateRange: String!, $organisationName: String, $storiesGroupId: String, $storyType: String, $toRunSlot: String, $section: String, $user: ID){
              viewer {
                  users(organisationName: $organisationName, first: 1000) {
                    edges {
                      node {
                        id
                        firstName
                        lastName
                      }
                    }
                  }

                  runsheetStoryGroups(organisationName: $organisationName, first: 1000, printOnly: true) {
                    edges {
                      node {
                        id
                        name
                      }
                    }
                  }

                  runsheetIdeas(
                      dateRange: $dateRange,
                      organisation: $organisation,
                      user: $user,
                      printOnly: true,
                      section: $section,
                      storyType: $storyType,
                      toRunSlot: $toRunSlot,
                      storiesGroupId: $storiesGroupId,
                      first: $$pageSize,
                      status: "Active"
                  ) {
                      pageInfo {
                        hasNextPage
                      }
                      edges {
                          node {
                              id

                              alternativeTaskId

                              date

                              idea {
                                node {
                                  id
                                  totalSharesCount
                                  headline
                                  description
                                  lastUpdated
                                  redFlag
                                  preferredDate
                                  storyType
                                  storyId
                                  channel
                                  section
                                  task {
                                    id
                                    storyTitle
                                    organisation {
                                      id
                                    }
                                  }
                                  storiesGroupId
                                  storiesGroup {
                                    node {
                                      id
                                      name
                                    }
                                  }
                                  creator {
                                    userId
                                  }
                                  sharedWithOrgs {
                                    id
                                    name
                                  }
                              }
                            }
                          }
                      }
                  }
              }
          }
      """).replace("$$pageSize", pageSize)

    loading = false

    holes = {}
    storyGroups = []
    issueId = null
    error = false

    data = []
    users = []

    filters =
        dateRange: NEWSLIST_DATE_RANGE_TODAY,
        storyGroup: null,
        storyType: NEWSLIST_STORY_TYPE[0]
        section: NEWSLIST_SECTION[0]
        user: ''
    params =
        pub: null
        organisation: null
        date: null

    subscribeAll = ->
      data.forEach (idea) ->
        AdonisService.subscribe [PRESENTER, 'task-' + idea.node.taskId], (result) =>
          holes[idea.node.id] = result.fitViews.reduce((acc, view) ->
            viewData = view[1]?[0]
            storyFit = viewData?.storyFit
            page = viewData?.pageNum
            [pub, year, month, day] = viewData?.issue.id.split('-')
            side = if storyFit?.side?.t == 'RightSidePage' then 'right' else 'left'
            acc.concat {
              holeId: storyFit.holeId.value,
              layoutId: "#{storyFit.spread.id}-#{side}"
              pageUrl: "/pages/#{pub}/#{year}-#{month}-#{day}/MAIN_BOOK/#{page}"
            }
          , [])

    _unsubscribeAll = ->
      data.forEach (idea) ->
        AdonisService.unsubscribe PRESENTER, 'task-' + idea.node.taskId
        delete holes[idea.node.id];

    storyGroups: ->
      storyGroups

    users: ->
      users

    all: ->
      data

    createCopyfitTask: (runsheetIdeaId, taskId, assigneeId) ->
      new Promise((resolve, reject) ->
        $http
          method: 'POST'
          url: API_URL
          data: {
            query: CREATE_COPYFIT_TASK_MUTATION_QUERY,
            variables: {
              input: {
                runsheetIdeaId: runsheetIdeaId,
                assigneeId: assigneeId
              }
            }
          }
          cache: false
          auth: true
        .success (result) =>
          resolve(result)
        .error (e) =>
          console.error("Failure", e)
          reject(e)
      )

    holes: (id) ->
      holes[id] || []

    getOrganisation: ->
        deferred = $q.defer()
        loading = true
        error = false
        AdonisData.getPublicationPresenter(params.pub)
            .then (data) =>
                return $http
                    method: 'GET'
                    url: "#{ORG_LOOKUP_URL}#{data.organisations[0]}/"
                    cache: false
                    auth: true
            .then ({data}) =>
                loading = false
                params.organisation = data.name
                deferred.resolve(data)
            .catch ->
                loading = false
                error = true
                deferred.reject()
        deferred.promise
    fetchIdeasForTasksList: (taskIds) ->
      deferred = $q.defer()

      Auth.token()
        .then (token) ->
          $http
            method: 'POST'
            url: API_URL
            data: {
              query: """
                query GetRunsheet($taskIds: [ID!]!, $count: Int){
                  viewer {
                    runsheetIdeasByTaskIds(first: $count, taskIds: $taskIds) {
                      edges {
                        node {
                          idea {
                            node {
                              task {
                                rawId
                              }
                            }
                          }
                          alternativeTaskId
                          tag
                        }
                      }
                    }
                  }
                }
              """
              variables: {
                count: taskIds.length
                taskIds: taskIds
              }
            }
            cache: false
            auth: true
          .success (result) ->
            deferred.resolve(result.data.viewer.runsheetIdeasByTaskIds.edges)
          .error (error) ->
            deferred.reject(error)
        .catch (e) ->
          deferred.reject(e)

      deferred.promise

    unsubscribeAll: ->
      _unsubscribeAll()

    query: (filters, pageSize) ->
        Auth.token().then (token) ->
            _unsubscribeAll()
            loading = true
            error = false

            $http
                method: 'POST'
                url: API_URL
                data: {
                    query: RUNSHEET_QUERY(pageSize)
                    variables: {
                        dateRange: filters.dateRange?.value,
                        storyType: filters.storyType?.value,
                        section: filters.section?.value,
                        printOnly: true,
                        toRunSlot: filters.toRunSlot,
                        organisation: params.organisation,
                        organisationName: params.organisation,
                        storiesGroupId: filters.storyGroup?.id && btoa("StoryGroup:" + filters.storyGroup?.id),
                        user: filters.user && btoa("User:" + filters.user)
                    }
                }
                cache: false
                auth: true
            .success (result) ->
                groups = result?.data?.viewer?.runsheetStoryGroups?.edges
                ideas = result?.data?.viewer?.runsheetIdeas?.edges

                users = [{name: "All", id: ""}].concat(
                  result?.data?.viewer?.users?.edges.map (item) ->
                    item.id = window.atob(item.node.id).split(":")[1]
                    item.name = item.node.firstName + " " + item.node.lastName
                    item
                )

                data = (ideas || [])
                  .map (ri) ->
                    item = ri.node.idea
                    item.node.alternativeTaskId = ri.node.alternativeTaskId
                    item.node.runsheetIdeaId = ri.node.id
                    item.node.preferredDate = ri.node.date || item.node.preferredDate
                    item
                  .filter((idea) -> idea.node.task)
                  .map (idea) ->
                    idea.node.taskId = atob(idea.node.task.id).replace(/\D/g, '')
                    idea

                subscribeAll()

                storyGroups = [{value: "", label: "Story Groups"}].concat((groups || []).map (group) ->
                  {
                    id: atob(group.node.id).split(":")[1],
                    label: group.node.name
                  })

                loading = false
            .error (err) ->
                error = true
                loading = false

    params: ->
      params
    issue: (id) ->
      issueId = id if id
      issueId
    queryFiltered: (pageSize) ->
      if (params.organisation)
        @query(filters, pageSize || 20)
      else
        defer = $q.defer()
        defer.reject("Organisation is not set")
        defer.promise
    updateFilters: (value) ->
      filters = value
    issueDate: ->
      [pub, year, month, day] = issueId.split('-') if issueId
      return if issueId then "#{day}-#{month}-#{year}" else null
    isLoading: ->
        loading
    isError: ->
        error