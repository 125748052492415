'use strict'

angular.module 'nn.page-info.directives', []

  .directive 'nnPageInfo', (AdonisData, $filter, nnSidenavService, AppState) ->
    templateUrl: '/templates/page-info.html'
    restrict: 'E'
    require: ['^canvasSchema']
    scope: { spread: '=', pages: '=', state: '=', copyfitStatus: '=' }
    controller: ($scope, PageExplorer) ->
      $scope.showTemplatesForPage = (pageNum) ->
        PageExplorer.showTemplates()
    link: (scope, element, attrs, ctrls) ->
      scope.schema = ctrls[0]
      scope.AppState = AppState

  .directive 'nnPageInfoHoles', (nnSidenavService, IssuePresenter, AppState) ->
    scope: { pages: '=', page: '=', schema: '=', spread: '=', state: '=', side: '@', copyfitStatus: '=' }
    link: (scope) ->
      scope.pageInfoPanel = nnSidenavService 'pageinfo'
      scope.AppState = AppState
      if scope.schema.previousHoleType == 'runsheet' && AppState?.page?.active?.newslistEnabled?.newslist
        scope.schema.setActiveHole 'runsheet', 'runsheet'
      scope.onClick = (el, key, id, override) ->
        scope.schema.active.side = scope.side
        if AppState?.page?.active?.newslistEnabled?.newslist and key is 'story' and el.task == null
          scope.schema.setActiveHole 'runsheet', 'runsheet'
        else
          scope.schema.setActiveHole el, key, id, override

    template: '''
      <div class="page-summary-header overflow-hidden">
        <i class="fa fa-times cursor-pointer pull-right closePanel cursor-pointer" ng-show="!spread.is_spread || (spread.is_spread && side === 'left')" ng-click="pageInfoPanel.close()"></i>
        <h6 ng-if="!spread.is_spread" class="alignc"> Page elements </h6>
        <h6 ng-if="spread.is_spread" class="alignc">{{ side | titleCase }} page elements </h6>
      </div>

      <a ng-repeat-end ng-repeat="(id, el) in page.adverts track by id"
          href="#"
          ng-click="onClick(el, 'advert', id, el.override)"
          class="list-group-item first-panel cursor-pointer">
        <div class="media-body nopadding nomargin summary">
          <i class="fa fa-circle" ng-class="(el.info.status == 'PUBLISHABLE') ? 'greenColor' : 'redColor'" ></i>
          <span class="text-lg text-thin label">{{'advert' | titleCase | makeHuman}} <small> - {{id}}</small></span>
          <i class="close-tip fa fa-angle-right fa-1 cursor-pointer"></i>
        </div>
      </a>

      <div ng-repeat="(key, holes) in page.layout.elements track by key">
        <a ng-repeat="(id, el) in holes track by id"
          href="#"
          ng-click="onClick(el, key, id, el.override)"
          class="list-group-item first-panel cursor-pointer">

          <div class="media-body nopadding nomargin summary">
            <i ng-if="key != 'story'" class="fa fa-circle" ng-class="((el.task || el.override.task) || el.info.status == 'PUBLISHABLE') ? 'greenColor' : 'redColor'" ></i>
            <i ng-if="key == 'story'" class="fa fa-circle" ng-class="{greenColor: copyfitStatus[el.task].publishable == true, redColor: copyfitStatus[el.task].publishable == false, greyColor: !copyfitStatus[el.task].publishable}" ></i>
            <span ng-if="key != 'advert'" class="text-lg  text-thin label">{{key | titleCase | makeHuman}} {{$index+1}} </span>
            <span ng-if="key == 'advert'" class="text-lg text-thin label">{{key | titleCase | makeHuman}} <small> - {{id}}</small></span>
            <i class="close-tip fa fa-angle-right fa-1 cursor-pointer"></i>
          </div>
        </a>
      </div>

      <!-- if last page AND barcode exists -->
      <a
        ng-show="page.barcode.barcode""
        href="#"
        class="list-group-item first-panel cursor-pointer"
        ng-click="onClick(page.barcode, 'barcode')"
        <div class="media-body nopadding nomargin summary">
          <i class="fa fa-circle"></i>
          <span class="text-lg text-thin label">Barcode</span>
          <i class="close-tip fa fa-angle-right fa-1 cursor-pointer"></i>
        </div>
      </a>
      '''

  .directive 'nnPageShare', ->
    scope: { page: '=', pages: '=' }
    template: '''
        <hr class="nomargin" />

        <div class="page-summary-header overflow-hidden" ng-if="page.layout.template">
          <i class="fa fa-times cursor-pointer pull-right closePanel cursor-pointer invisible" ng-click="pageinfoCtrl.close()"></i>
          <h6 class="alignc">Share page layout</h6>
        </div>

        <div class="page-elements-share" ng-if="page.layout.template">
            <table class="shared-pages">
              <thead>
                <tr>
                  <th class="alignc shared-pages-header pub">Publication</th>
                  <th class="alignc shared-pages-header " >Date</th>
                  <th class="alignc shared-pages-header page" >Page</th>
                  <th class="alignc shared-pages-header "></th>
                </tr>
              </thead>
              <tbody>
                <tr ng-repeat="sharedPage in sharedPages | orderBy:['publicationName','date','folioNumber']">
                  <td class="pub">{{sharedPage.publicationName}}</td>
                  <td class="alignc">{{sharedPage.date.format('DD/MM/YYYY')}}</td>
                  <td class="alignc page">
                    <a ng-if="!(page.page == sharedPage.folioNumber && page.spreadId == sharedPage.spread.id)"
                       ng-click="preview(sharedPage, '_self')">{{sharedPage.folioNumber}}</a>
                    <span ng-if="(page.page == sharedPage.folioNumber && page.spreadId == sharedPage.spread.id)">{{sharedPage.folioNumber}}</span>
                  </td>
                  <td class="alignc"><i ng-if="!sharedPage.hasDefaultLayout" ng-click="remove(sharedPage)" class="fa fa-times redColor cursor-pointer"></i></td>
                </tr>
                <tr ng-if="sharedPages">
                  <td class="alignc">
                    <nn-publication-select panels="select2-width" selected="newSharedPage.publication"></nn-publication-select>
                  </td>
                  <td class="alignc">
                    <quick-datepicker
                      placeholder="Date"
                      ng-model="newSharedPage.date"
                      ng-change="update(newSharedPage)"
                      icon-class="invisible"
                      name="share-issue-date"
                      disable-timepicker='true'
                      disable-ok-button='true'
                      disable-clear-button='true'>
                    </quick-datepicker>
                  </td>
                  <td class="alignc page">
                    <ui-select
                      name="sharedPageNum"
                      ng-model="newSharedPage.page"
                      ng-disabled="!(newSharedPage.publication && newSharedPage.pages.length)"
                      theme="select2" search-enabled="false">
                      <ui-select-match>
                        {{ newSharedPage.page.folioNumber }}
                      </ui-select-match>
                      <ui-select-choices
                        repeat="page in newSharedPage.pages | filter: $select.search">
                        {{ page.folioNumber }} {{ page.displayText }}
                      </ui-select-choices>
                    </ui-select>
                    <span ng-if="!(newSharedPage.publication && newSharedPage.pages.length)"> - </span>
                  </td>
                  <td class="alignc remove">

                    <i ng-click="submit(newSharedPage, page.details.layout())"
                       ng-if="newSharedPage.page && newSharedPage.publication.code && newSharedPage.date"
                       class="fa fa-plus fa-lg block greenColor cursor-pointer"></i>
                       <small ng-if="newSharedPage.page && newSharedPage.publication.code && newSharedPage.date">
                      <a ng-click="preview(newSharedPage, '_blank')">(preview)</a>
                    </small>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
      '''
    controller: ($scope, $state, $window, moment, SharedPages, LayoutPagePlacementPresenter, NotificationService) ->
      $scope.subscribedData = null
      currentSubscribedLayout = null

      resetNewShare = ->
        $scope.newSharedPage =
          publication: null
          pages: []
          page: null
          date: if $scope.pages then moment($scope.pages.info.formattedDate, "DD/MM/YYYY") else moment()
      resetNewShare()

      #Get/subscribe to changes to shared pages for this page.
      $scope.$watch 'page.details.layout()', (newVal, oldVal) ->
        if oldVal?
          LayoutPagePlacementPresenter.deleteSubscription 'PageEditorialLayout.'+ oldVal
          resetNewShare()

        if newVal?
          LayoutPagePlacementPresenter.getSubscription('PageEditorialLayout.'+ newVal).then (data) ->
            $scope.subscribedData = data
            currentSubscribedLayout = newVal

      $scope.$watchCollection 'subscribedData', (newval) ->
        if newval?.data
          $scope.sharedPages = newval.data.placements.map (item) ->
            { issue, bookName, folioNumber } = item
            [pub, year, month, day] = issue.id.split '-'
            angular.extend item,
              date: moment "#{year}-#{month}-#{day}"
              page:
                book: bookName
                folioNumber: folioNumber
              publication:
                code: pub
          $scope.update $scope.newSharedPage

      $scope.remove = (sharedPage) ->
        SharedPages.remove sharedPage.folioNumber, sharedPage.spread.id

      $scope.submit = (sharePage, srcSpread) ->
        if sharePage.page.stories.length
          NotificationService.confirm('You’re about to apply this layout to another page which will
                                remove the existing layout and content. You can return to the previous
                                layout and content by unsharing').then ->
            SharedPages.create sharePage.page.folioNumber, sharePage.page.spread.id, srcSpread
        else
          SharedPages.create sharePage.page.folioNumber, sharePage.page.spread.id, srcSpread

      $scope.update = (sharePage) ->
        $scope.newSharedPage.page = null
        if $scope.newSharedPage.publication?
          id = $scope.newSharedPage.publication.code+'-'+$scope.newSharedPage.date.format('YYYY-MM-DD')
          SharedPages.get(id, $scope.sharedPages, $scope.page.edspace, sharePage, $scope.page.page).then (pages)->
            sharePage.pages = pages

      $scope.preview = ({ publication, date, page }, type) ->
        { book, folioNumber } = page

        params =
          pub: publication.code
          date: date.format 'YYYY-MM-DD'
          book: book
          page: folioNumber

        url = $state.href 'app.pages.issue.page',
          params
          absolute: true

        $window.open url, type
        return

      #Cant use ng-change to update state for a publication change hence we watch for change on this field.
      $scope.$watch 'newSharedPage.publication', (newVal, oldVal) ->
        if newVal?
          $scope.update $scope.newSharedPage

      $scope.$on '$destroy', ->
        SharedPages.destroy()
        LayoutPagePlacementPresenter.deleteSubscription 'PageEditorialLayout.'+ currentSubscribedLayout

  .directive 'nnIssueRunsheet', (FeatureAccess, AppState) ->
    link: (scope) ->
      scope.isNewslistEnabled = AppState?.page?.active?.newslistEnabled?.newslist
    template: '''
      <a
        href="#"
        ng-show="isNewslistEnabled"
        class="list-group-item first-panel cursor-pointer"
        ng-click="schema.setActiveHole('runsheet', 'runsheet')">
        <div class="media-body nopadding nomargin summary">
          <i class="fa fa-podcast green"></i>
          <span class="text-lg text-thin label">Live Runsheet</span>
          <i class="close-tip fa fa-angle-right fa-1 cursor-pointer"></i>
        </div>
      </a>
    '''
    restrict: 'E'
    scope:
      page: '='
      schema: '='

  .directive 'nnRunsheetDetails', ->
    templateUrl: '/templates/runsheet-details.html'
    restrict: 'E'
    scope:
        spread: '='
        page: '='
        schema: '='
    controller: ($scope, Runsheet, AdonisData, moment, NEWSLIST_STORY_TYPE, NEWSLIST_DATE_RANGE, NEWSLIST_SECTION,
      NEWSLIST_CHANNELS, RUNSHEET_PAGE_SIZE_OFFSET, NEWSLIST_DATE_RANGE_HELD_OVER, NEWSLIST_DATE_RANGE_TODAY,
      NEWSLIST_DATE_RANGE_NEXT_WEEK, NEWSLIST_DATE_RANGE_NEXT_MONTH, User) ->

      $scope.runsheet = Runsheet;

      $scope.runsheetFilters =
        dateRange: NEWSLIST_DATE_RANGE_TODAY,
        storyGroup: null,
        storyType: NEWSLIST_STORY_TYPE[0]
        section: NEWSLIST_SECTION[0]
        user: ''

      $scope.isOpen = false
      $scope.pageSize = RUNSHEET_PAGE_SIZE_OFFSET
      $scope.lastLoadFilters = {}
      $scope.expandedItems =[]
      $scope.storyTypes = NEWSLIST_STORY_TYPE
      $scope.sections = NEWSLIST_SECTION
      $scope.dateRanges = NEWSLIST_DATE_RANGE
      $scope.channels = NEWSLIST_CHANNELS
      $scope.copyfitTasksEnabled = false
      $scope.hasNextPage = false

      $scope.isAssigned = (idea) ->
        if $scope.copyfitTasksEnabled
          hole = Runsheet.holes(idea.node.id).find (hole) ->
            hole.layoutId.substring(0, hole.layoutId.indexOf("-")) == $scope.page.details.pub
          !!hole
        else
          !!Runsheet.holes(idea.node.id).length

      $scope.getTaskId = (taskId) ->
        decoded = window.atob(taskId)
        decoded.substring(decoded.indexOf(":") + 1)

      $scope.refresh = () ->
        Runsheet.queryFiltered($scope.pageSize)
          .then (result) ->
            $scope.hasNextPage = result.data.data.viewer.runsheetIdeas?.pageInfo?.hasNextPage

      $scope.loadMore = () ->
        $scope.pageSize =
          if ($scope.hasNextPage)
            $scope.pageSize + RUNSHEET_PAGE_SIZE_OFFSET
          else if (_.isEqual($scope.lastLoadFilters, $scope.runsheetFilters) && !$scope.hasNextPage)
            $scope.pageSize
          else
            RUNSHEET_PAGE_SIZE_OFFSET

        $scope.lastLoadFilters = _.clone($scope.runsheetFilters)

        $scope.refresh()

      $scope.toggleExpand = (item) ->
        exists = $scope.expandedItems.indexOf(item)
        if exists > -1 then $scope.expandedItems.splice(exists, 1) else $scope.expandedItems.push(item)

      $scope.getMappedLabel = (coll, value) ->
        match = coll.filter (i) -> i.value == value
        if match.length
          return match[0].label
        ''

      $scope.toDate = (date) ->
        moment(date).format("YYYY-MM-DD")

      $scope.$watch "runsheetFilters", ((nv, ov) ->
        $scope.runsheet.updateFilters(nv)
        $scope.refresh()
      ), true

      $scope.$watch 'spread.info', (nv, ov) ->
        [pub, year, month, day, rest...] = nv.spread_id.split("-")
        issueId = "#{pub}-#{year}-#{month}-#{day}"
        if nv && issueId != Runsheet.issue()
          Runsheet.unsubscribeAll()
          Runsheet.issue(issueId)
          Runsheet.params().date = "#{year}-#{month}-#{day}"
          Runsheet.params().pub = pub

          Runsheet.getOrganisation()
            .then (org) ->
              $scope.$$postDigest -> $scope.viewLoaded = true
              Runsheet.queryFiltered($scope.pageSize)

      $scope.$on '$destroy', ->
        Runsheet.unsubscribeAll()

  .directive 'nnFurnitureDetails', ->
    templateUrl: '/templates/furniture-details.html'
    restrict: 'E'
    scope: { page: '=', schema: '=', task: '=' }
    controller: ($scope) ->
      $scope.$$postDigest -> $scope.viewLoaded = true

  .directive 'nnStoryDetails', ->
    templateUrl: '/templates/story-details.html'
    restrict: 'E'
    scope: { page: '=', schema: '=', task: '=' }
    controller: ($scope) ->
      $scope.$$postDigest -> $scope.viewLoaded = true

  .directive 'nnAdvertDetails', ->
    templateUrl: '/templates/advert-details.html'
    restrict: 'E'
    scope: { advert: '=' }
    controller: ($scope) ->
      $scope.$$postDigest -> $scope.viewLoaded = true

  .directive 'nnBarcodeDetails', ->
    templateUrl: '/templates/barcode-details.html'
    restrict: 'E'
    scope: { page: '=' }
    controller: ($scope) ->
      $scope.$$postDigest -> $scope.viewLoaded = true

  .directive 'closeSecondPanel', (nnSidenavService) ->
    restrict: 'A'
    link: (scope, element) ->
      element.on 'click', (e) ->
        if scope.asideCtrl.active()
          if e.target.id is 'aside-container' or e.target.nodeName in ['H6'] or e.target.classList.contains 'list-group-item'
            scope.$apply scope.schema.setActiveHole()
