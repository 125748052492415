'use strict'

angular.module 'nn.story-manager', [
    'nn.story-manager.routes'
    'nn.story-manager.services'
    # components
    'nn.story-explorer'
    'nn.story-search'
    'nn.story-list'
    'nn.story-editor'
    'nn.story-toolbar'
    'nn.story-info'
    'nn.story-info-pagebuilder'
    'nn.story-snapshots'
    'nn.story-breakingnews'
    'nn.story-digital-quality-checklist'
    'nn.story-assistance'
]

.run (
    $state,
    $rootScope,
    $q,
    TaskService,
    NotificationService,
    Story,
    StoryUtils,
    STORY_CHANNEL,
    STORY_STATUS,
    STORY_VISIBILITY
) ->

    MSG =
        UPDATE_CONFIRM: (title) -> "Please note that this story ('#{title}') is not published to web. Would you like us to publish it online now?"
        UPDATE_SUCCESSFUL: "Story is now published to web"
        UPDATE_ERROR: "There was a problem publishing this story to web, please try again later"
        GENERIC_ERROR: "A problem was encountered, please try again later"

    storyNotifications = {}

    continueStateTransition = null

    # on state change, we check whether the story is 'web-ready' before
    # transitioning away from storypad.
    #
    # web-readiness depends on 'story.visibility' and 'story.status'
    #
    # if the story isn't 'web-ready', we ask the user if they'd like
    # to update the story with the required story settings.
    #
    # otherwise we just continue the original state transition

    $rootScope.$on '$stateChangeStart', (event, toState, toParams, fromState, fromParams) ->
        continueStateTransition = ->
            $state.go toState, toParams, notify:false
            return
        # skip when route change is the result of a story save (ie. snapshot change)
        stateTransitionFromStory =
            fromState.name in ['app.stories.story', 'app.stories.story.snapshot'] and toState.name isnt 'app.stories.story.snapshot'
        { story, snapshot } = fromParams
        if stateTransitionFromStory and !storyNotifications[story]?
            event.preventDefault()
            getStoryStatus story, snapshot
            .then checkStoryHasTask
            .then checkTaskRedFlaggedOrPrintOnly
            .then checkSnapshotPrintOnly
            .then checkStoryWebReady
            .then showUpdateConfirmNotification
            .then updateStory
            .then showUpdateResultNotification
            .catch handleError
            .then continueStateTransition

    getStoryStatus = (story_id, snapshot_id) ->
        Story.load story_id, snapshot_id
            .then (story) ->
                story
            .catch ->
                $q.reject()

    checkStoryHasTask = (story) ->
        storyHasTask = story.storytask?
        if storyHasTask
            story
        else
            $q.reject()

    checkTaskRedFlaggedOrPrintOnly = (story) ->
        if story.storytask?
            TaskService.one story.storytask
                .then (task) ->
                    taskIsRedFlagged = task.flagged
                    taskIsPrintOnly = task.available_to?.length is 1 and STORY_CHANNEL.PRINT.value in task.available_to
                    if taskIsRedFlagged
                        $q.reject()
                    else if taskIsPrintOnly
                        $q.reject()
                    else
                        story
                .catch ->
                    $q.reject()
        else
            story

    checkSnapshotPrintOnly = (story) ->
        if StoryUtils.storyIsPrintOnly story
            $q.reject()
        else
            story

    checkStoryWebReady = (story) ->
        storyHasPublicVisibility = story.visibility is STORY_VISIBILITY.PUBLIC.value
        webApprovedSnapshotExists = story.snapshot_pointers?.web?.approved?
        storyIsWebReady = storyHasPublicVisibility and webApprovedSnapshotExists
        if !storyIsWebReady
            story
        else
            $q.reject()

    showUpdateConfirmNotification = (story) ->
        msg = MSG.UPDATE_CONFIRM story.title
        storyNotifications[story.id] = true
        NotificationService.confirm msg, '', true
            .then ->
                delete storyNotifications[story.id]
                story
            .catch ->
                delete storyNotifications[story.id]
                $q.reject()

    updateStory = (story) ->
        storyHasPublicVisibility = story.visibility is STORY_VISIBILITY.PUBLIC.value
        webApprovedSnapshotExists = story.snapshot_pointers?.web?.approved?
        # if only 'visibility' needs to be updated (story level prop), update the story
        if webApprovedSnapshotExists and !storyHasPublicVisibility
            props =
                visibility: STORY_VISIBILITY.PUBLIC.value
            Story.update story.id, null, props
                .catch ->
                    $q.reject new Error 'error updating story visibility'
        # otherwise if 'status' update required (snapshot level prop), a new snapshot will be created
        else if !webApprovedSnapshotExists
            props =
                status: STORY_STATUS.APPROVED.value
            if !storyHasPublicVisibility
                props.visibility = STORY_VISIBILITY.PUBLIC.value
            Story.createSnapshot story.id, story.snapshot, props
                .catch ->
                    $q.reject new Error 'error creating new approved story snapshot'

    showUpdateResultNotification = ->
        NotificationService.success MSG.UPDATE_SUCCESSFUL
        return

    handleError = (error) ->
        NotificationService.error MSG.GENERIC_ERROR if error?
        return
