'use strict'

angular.module 'nn.task.services', []

    .factory 'TaskService', (TaskServiceProvider) ->
        TaskServiceProvider.getInstance()

    .constant 'TASK_ORGANIZATION',
        ALL: 'all'

    .constant 'TASK_ASSIGNEE',
        ALL: 'all'
        USER: 'user'

    .constant 'TASK_STATUS',
        ALL: 'all'
        OPEN: 'new'
        ARCHIVED: 'done'

    .factory 'FurnitureObject', ($http, HOSTS) ->
        create: (data) ->
            $http
                method: 'POST',
                url: "#{HOSTS.silverstone}/api/v2/furniture/"
                data: data
                cache: false
                auth: true
        update: (id, data) ->
            $http
                method: 'PATCH',
                url: "#{HOSTS.silverstone}/api/v2/furniture/#{id}/"
                data: data
                cache: false
                auth: true

    .factory 'TaskServiceProvider', ($http, $q, Auth, SilverstoneConfig, Runsheet, HOSTS, $analytics, PageBuilderModuleTypes, Workflow, TASK_ASSIGNEE, TASK_STATUS, TASK_ORGANIZATION, AdonisData) ->
        class Task
            @API_URL: "#{SilverstoneConfig.api_url}"
            constructor: ->
                @tasks = []
                @loading = false
                @end = false
                @content_type = 'story'
                @page = 1
                @page_size = 5
                @flagged = false
                @order_by = '-updated_on'
                @organization = TASK_ORGANIZATION.ALL
                @assignee = TASK_ASSIGNEE.ALL
                @status = TASK_STATUS.ALL
            all: ->
                @tasks
            create: (data, api, workflow) ->
                deferred = $q.defer()
                $http
                    method: 'POST',
                    url: "#{Task.API_URL}task/"
                    data: data
                    cache: false
                    auth: true
                .success (response) ->
                    task_id = response.id
                    if workflow?.id
                        Workflow.setWorkflowForTask(task_id, workflow.id).then (data) ->
                            deferred.resolve { task: response, workflow: data }
                    else deferred.resolve { task: response }
                    $analytics.eventTrack api + ' Task Created', 'id':task_id
                .error (response) ->
                    deferred.reject response
                deferred.promise
            update: (task_id, data) ->
                $http
                    method: 'PATCH',
                    url: "#{Task.API_URL}task/#{task_id}/"
                    data: JSON.stringify data
                    cache: false
                    auth: true
            remove: (task_id) ->
                $http
                    method: 'DELETE',
                    url: "#{Task.API_URL}task/#{task_id}/"
                    cache: false
                    auth: true
            removeStory: (task_id) ->
              payload =
                content_object:
                  id: null
                  type: 'story'
              @update task_id, payload
            refresh: (nextPage=false) =>
                @loading = true
                if nextPage
                    @page += 1
                else
                    @page = 1
                Auth.user().then (user) =>
                    url = "#{Task.API_URL}task/?"
                    url += "page_size=#{@page_size}" if @page_size?
                    url += "&page=#{@page}" if @page?
                    url += "&ordering=#{@order_by}" if @order_by?
                    url += "&flagged=True" if @flagged
                    if @organization? and @organization isnt TASK_ORGANIZATION.ALL
                        url += "&organization=#{@organization}"
                    else
                        url += "&organization=self"
                    if @status? and @status isnt TASK_STATUS.ALL
                        url += "&status=#{@status}"
                    if @assignee is TASK_ASSIGNEE.USER
                        url += "&assignee=#{user.id}"
                    else if @assignee? and @assignee isnt TASK_ASSIGNEE.ALL
                        url += "&assignee=#{@assignee}"
                    url += "&content_type=#{@content_type}" if @content_type?

                    $http
                        method: 'GET'
                        url: url
                        cache: false
                        auth: true
                    .success (response) =>
                        taskIds = response.results.map((task) => task.id)
                        enhancedTasks = response.results.map((task) ->
                            AdonisData.getStoryIssuesPresenter(task.id).then(
                                    (data) ->
                                        [pub, y, m, d, book] = data.layout.split '-'
                                        task.page = pub: pub, book: book
                            )
                            task
                        )

                        @end = !response.next?
                        if nextPage
                          @tasks = @tasks.concat enhancedTasks
                        else
                          @tasks = enhancedTasks
                    .then =>
                        @loading = false
                @
            more: ->
                @refresh true
            one: (id) ->
                deferred = $q.defer()
                $http
                    method: 'GET',
                    url: "#{Task.API_URL}task/#{id}/"
                    cache: false
                    auth: true
                .success (response) ->
                    deferred.resolve response
                .error (response) ->
                    deferred.reject response
                deferred.promise
            oneForStory: (story_id) ->
                deferred = $q.defer()
                $http
                    method: 'GET',
                    url: "#{Task.API_URL}task/?story=" + story_id
                    cache: false
                    auth: true
                .success (response) ->
                    deferred.resolve response
                .error (response) ->
                    deferred.reject response
                deferred.promise
        getInstance: ->
            new Task
