'use strict'

angular.module 'nn.auth.services', []

  .constant 'OAUTH_CONFIG',
    LOGIN:
      grant_type: 'password'
      scope: 'organizations.readonly suzuka valencia silverstone dijon bathurst profile email'
      client_id: 'newsnow-client-id'
    REFRESH_TOKEN:
      grant_type: 'refresh_token'
      client_id: 'newsnow-client-id'

  # Options are 'monza', 'cognito'
  .constant 'NEWSNOW_AUTH_SERVICE', null

  .constant 'COGNITO_REDIRECT_URL', "#{window.location.protocol}//#{window.location.host}/login/"

  .constant 'COGNITO_LOGIN_URL',
      PRODUCTION: 'https://monza-production.auth.ap-southeast-2.amazoncognito.com/oauth2/authorize/'
      UAT: 'https://monza-uat.auth.ap-southeast-2.amazoncognito.com/oauth2/authorize/'
      STAGING: 'https://monza-staging.auth.ap-southeast-2.amazoncognito.com/oauth2/authorize/'
      DEV: 'https://monza-development.auth.ap-southeast-2.amazoncognito.com/oauth2/authorize/'

  .constant 'COGNITO_IDP', 'Onelogin'

  .constant 'COGNITO_CLIENT_ID',
      PRODUCTION: '5u8bmrv8hq1dsmvtsp9b3tqqle'
      UAT: '2duah0j817u8s4ag5u1oodfq2t'
      STAGING: '5kl5o5iip6a54tat964tj6t76k'
      DEV: '4u4ipilriiddi1t9q5a6l9ev21'

  .factory 'CognitoLoginUrl', (Environment, ENVIRONMENTS, COGNITO_LOGIN_URL) ->
      switch Environment
          when ENVIRONMENTS.PRODUCTION, ENVIRONMENTS.PRODUCTION_INTERNAL, ENVIRONMENTS.PRODUCTION_TEMP, ENVIRONMENTS.PRODUCTION_DR
              COGNITO_LOGIN_URL.PRODUCTION
          when ENVIRONMENTS.UPDATE, ENVIRONMENTS.UPDATE_INTERNAL
              COGNITO_LOGIN_URL.PRODUCTION
          when ENVIRONMENTS.UAT, ENVIRONMENTS.TRAINING, ENVIRONMENTS.UAT2, ENVIRONMENTS.UAT3
              COGNITO_LOGIN_URL.UAT
          when ENVIRONMENTS.STAGING, ENVIRONMENTS.STAGING2
              COGNITO_LOGIN_URL.STAGING
          when ENVIRONMENTS.DEV, ENVIRONMENTS.DEV2
              COGNITO_LOGIN_URL.DEV
          else
              COGNITO_LOGIN_URL.DEV

  .factory 'CognitoClientId', (Environment, ENVIRONMENTS, COGNITO_CLIENT_ID) ->
      switch Environment
          when ENVIRONMENTS.PRODUCTION, ENVIRONMENTS.PRODUCTION_INTERNAL, ENVIRONMENTS.PRODUCTION_TEMP, ENVIRONMENTS.PRODUCTION_DR
              COGNITO_CLIENT_ID.PRODUCTION
          when ENVIRONMENTS.UPDATE, ENVIRONMENTS.UPDATE_INTERNAL
              COGNITO_CLIENT_ID.PRODUCTION
          when ENVIRONMENTS.UAT, ENVIRONMENTS.TRAINING, ENVIRONMENTS.UAT2, ENVIRONMENTS.UAT3
              COGNITO_CLIENT_ID.UAT
          when ENVIRONMENTS.STAGING, ENVIRONMENTS.STAGING2
              COGNITO_CLIENT_ID.STAGING
          when ENVIRONMENTS.DEV, ENVIRONMENTS.DEV2
              COGNITO_CLIENT_ID.DEV
          else
              COGNITO_CLIENT_ID.DEV

  .constant 'AuthEvent',
    TOKEN_REFRESHED: 'token-refreshed'

  .factory 'Auth', ($http, $q, $rootScope, $cacheFactory, HOSTS, OAUTH_CONFIG, AuthEvent, transformRequestAsFormPost, COGNITO_REDIRECT_URL) ->
    refreshTokenReceiversQueue = []
    refreshTokenInProgress = false

    userData = null
    orgData = null
    urls =
      PASSWORD_RESET: "#{HOSTS.monza}/password/reset/?theme=newsnow"
    # loginDeferred = $q.defer()
    # userDeferred = null
    # tokenDeferred = $q.defer()
    # user = null
    login = (username, password) ->
      deferred = $q.defer()
      $http
        method: 'POST'
        url: HOSTS.monza + '/o/token/'
        data: angular.extend OAUTH_CONFIG.LOGIN, {username, password}
        transformRequest: transformRequestAsFormPost
        cache: false
      .success (data, status, headers, config) ->
        localStorage.setItem 'oauth', JSON.stringify data
        localStorage.setItem 'token', data.access_token
        deferred.resolve()
      .error (data, status, headers, config) ->
        deferred.reject data, status
      deferred.promise
    cognito = (code) ->
      deferred = $q.defer()
      $http
        method: 'GET'
        url: HOSTS.monza + '/cognito/authorize/'
        params:
          code: code
          response_type: 'token'
          client_id: OAUTH_CONFIG.LOGIN.client_id
          scope: OAUTH_CONFIG.LOGIN.scope
          redirect_uri: COGNITO_REDIRECT_URL
        cache: false
      .success (data, status, headers, config) ->
        localStorage.setItem 'oauth', JSON.stringify data
        localStorage.setItem 'token', data.access_token
        deferred.resolve()
      .error (data, status, headers, config) ->
        deferred.reject data, status
      deferred.promise
    cognitoConfig = (code) ->
      deferred = $q.defer()
      $http
        method: 'GET'
        url: HOSTS.monza + '/cognito/config/'
        cache: false
      .success (data, status, headers, config) ->
        deferred.resolve(data)
      .error (data, status, headers, config) ->
        deferred.reject data, status
      deferred.promise
    logout = ->
      userData = null
      orgData = null
      localStorage.removeItem 'oauth'
      localStorage.removeItem 'organizations'
      localStorage.removeItem 'user'
      localStorage.removeItem 'token'
      # after logout/login, token header changes so cache won't be used
      # however empty the http cache anyway as a memory consideration
      $cacheFactory.get('$http').removeAll()
      # userDeferred = null
      $q.when()
    refresh = ->
      deferred = $q.defer()

      tokenRefreshOperationComplete = (success, data) ->
        refreshTokenReceiversQueue.forEach( (item) ->
          if (success)
            item.resolve(data.token)
          else
            item.reject data.data, data.status
        )
        refreshTokenReceiversQueue = []
        refreshTokenInProgress = false

      if refreshTokenInProgress
        refreshTokenReceiversQueue.push(deferred)
      else
        refreshTokenInProgress = true
        refreshToken().then (token) ->
          $http
            method: 'POST'
            url: HOSTS.monza + '/o/token/'
            data: angular.extend OAUTH_CONFIG.REFRESH_TOKEN, {refresh_token:token}
            transformRequest: transformRequestAsFormPost
            cache: false
          .success (data, status, headers, config) ->
            token = data.access_token
            localStorage.setItem 'oauth', JSON.stringify data
            localStorage.setItem 'token', data.access_token
            tokenRefreshOperationComplete(true, 'token': token)
            $rootScope.$emit AuthEvent.TOKEN_REFRESHED, 'token':token
            deferred.resolve token
          .error (data, status, headers, config) ->
            tokenRefreshOperationComplete(false, ('data': data, 'status': status))
            deferred.reject data, status
        , ->
          deferred.reject()
      deferred.promise
    user = (type = 'user') ->
      if userData? and type is 'user'
        $q.when userData
      else if orgData? and type is 'org'
        $q.when orgData
      else if userData? and orgData? and type is 'all'
        $q.when "user": userData, "orgs": orgData
      else
        deferred = $q.defer()
        $http
          method: 'GET'
          url: "#{HOSTS.monza}/o/tokeninfo/"
          cache: true
          auth: true
        .success (data) ->
          userData = data.user
          orgData = data.organizations
          localStorage.setItem 'user', JSON.stringify data.user
          localStorage.setItem 'organizations', JSON.stringify data.organizations
          deferred.resolve userData if type is 'user'
          deferred.resolve orgData if type is 'org'
          deferred.resolve ("orgs": orgData, "user": userData) if type is 'all'
        .error (data) ->
          deferred.reject()
        deferred.promise
    token = ->
      deferred = $q.defer()
      data = JSON.parse localStorage.getItem 'oauth'
      token = data?['access_token']
      if token?
        deferred.resolve token
      else
        deferred.reject()
      deferred.promise
    refreshToken = ->
      deferred = $q.defer()
      data = JSON.parse localStorage.getItem 'oauth'
      token = data?['refresh_token']
      if token?
        deferred.resolve token
      else
        deferred.reject()
      deferred.promise
    isAuthorised = ->
      data = JSON.parse localStorage.getItem 'oauth'
      token = data?['access_token']
      token?
    return {
      login: login
      cognito: cognito
      cognitoConfig: cognitoConfig
      logout: logout
      refresh: refresh
      user: user
      token: token
      isAuthorised: isAuthorised
      urls: urls
    }


.factory 'authInterceptor', ($injector, $q) ->
    refreshInProgress = false
    requestQueue = []
    tokenParamRegex = /token=([^&]*)/
    tokenInfoRegex = /o\/tokeninfo/
    domainRegex = /(newsnow.io|fairfaxregional.com.au)/

    resumeRequestQueue = (token) ->
      for request in requestQueue
        do (request) ->
          if request.config.headers.Authorization?
            # request uses token header
            request.config.headers.Authorization = "Bearer #{token}"
          else if tokenParamRegex.test request.config.url
            # request uses token param
            request.config.url = request.config.url.replace tokenParamRegex, "token=#{token}"
          $injector.get('$http')(request.config).then (response) ->
            request.deferred.resolve response
            requestQueue.splice requestQueue.indexOf(request), 1
          , (response) ->
            request.deferred.reject()

    refreshTokenThenResumeRequests = ->
      refreshInProgress = true
      refreshTokenSuccess = (token) ->
        refreshInProgress = false
        resumeRequestQueue token
      refreshTokenError = ->
        refreshInProgress = false
        $injector.get('$state').go 'logout'
      $injector.get('Auth').refresh().then refreshTokenSuccess, refreshTokenError

    interceptor =
      request: (config) ->
        if config.auth is true
          config.headers = config.headers or {}
          $injector.get('Auth').token().then (token) ->
            config.headers.Authorization = "Bearer #{token}"
        config
      responseError: (response) ->
        url = response.config.url
        authSupportedDomain = domainRegex.test(url)
        if !authSupportedDomain
          $q.reject response
        else
          switch response.status
            when 401, 403
              if $injector.get('$state').is 'login'
                # passthrough on login
                $q.reject response
              else if response.status is 403 and !tokenInfoRegex.test(response.config.url)
                # passthrough for 403's that aren't hitting /tokeninfo
                # eg. private story
                $q.reject response
              else if response.config?.data?.grant_type is 'refresh_token'
                # passthrough for token refresh (as 401 on token refresh
                # likely means the refresh_token is invalid)
                $q.reject response
              else
                # otherwise queue the request, attempt a token refresh,
                # then resume queued requests using the new token
                deferred = $q.defer()
                requestQueue.push {'config':response.config, 'deferred':deferred}
                if !refreshInProgress
                  refreshTokenThenResumeRequests()
                deferred.promise
            else
              $q.reject response


  .factory 'transformRequestAsFormPost', ->
    # serialize the data into a key-value string
    serializeData = (data) ->
      buffer = []
      for key, value of data
        buffer.push encodeURIComponent(key) + '=' + encodeURIComponent(value)
      buffer.join('&').replace(/%20/g, '+')
    # return request transform function
    (data, getHeaders) ->
      headers = getHeaders()
      headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=utf-8'
      # $.param(data).replace /%20/g, '+'
      serializeData data
