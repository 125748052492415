'use strict'

angular.module 'nn.story-toolbar.directives', []

    .directive 'nnStoryToolbar', () ->
        templateUrl: '/templates/story-toolbar.html'
        restrict: 'E'
        scope:
            storyModel: '='
            storypad: '='
            storySaving: '='
            task: '='
            hasDigitalPackageAccess: '='
        controller: (
            $scope,
            $rootScope,
            $state,
            $timeout,
            $q,
            $window,
            StoryInfo,
            nnSidenavService,
            StorypadMessageType,
            StoryAssistanceMessageType,
            StoryAssistanceRequestType,
            OrganizationIds
            NotificationService,
            Story,
            STORY_STATUS,
            STORY_STATUS_OPTIONS
            STORY_VISIBILITY,
            STORY_VISIBILITY_OPTIONS,
            STORY_CHANNEL,
            STORY_CHANNEL_OPTIONS,
            moment,
            User,
            FeatureAccess,
            AppState,
            BreakingNews
        ) ->
            $scope.storyinfo = StoryInfo
            $scope.STORY_VISIBILITY = STORY_VISIBILITY
            $scope.STORY_CHANNEL = STORY_CHANNEL
            $scope.STORY_STATUS = STORY_STATUS
            $scope.storyVisibilityOptions = STORY_VISIBILITY_OPTIONS
            $scope.storyChannelOptions = STORY_CHANNEL_OPTIONS
            $scope.storyStatusOptions = STORY_STATUS_OPTIONS
            $scope.showSocialEngegment = false
            $scope.clickedSaveButton = false
            $scope.storyNeverPublished = false
            $("#layuout-tool-bar").hide();
            $("#story-layout").hide();

            $scope.showCopyDialog = false

            $q.all([nnSidenavService('storyinfo'), nnSidenavService('mside')]).then (panels) ->
                $scope.storyinfoCtrl = panels[0]
                $scope.msideCtrl = panels[1]

            # enable settings panel after 5 secs, an interim fix of Dijon lists to populate
            $scope.settingsEnabled = false
            $timeout ->
                $scope.settingsEnabled = true
            , 3000

            # update story visibility/channel/status labels
            $scope.$watch 'storyModel', (newValue, oldValue) ->
                if $scope.storyModel
                    if $scope.clickedSaveButton is false
                        $scope.storyNeverPublished = false
                    FeatureAccess.hasSocialEngagementAccess($scope.storyModel.organization).then (result) ->
                        if result
                            $scope.showSocialEngegment = result
                        else
                            $scope.showSocialEngegment = false
                else
                    $scope.storyNeverPublished = true

                if newValue?
                    { visibility, available_to, status } = newValue
                    channel = if available_to?.length > 1 then STORY_CHANNEL.ALL.value else available_to?[0]
                    $scope.selectedVisibility = STORY_VISIBILITY_OPTIONS.filter((option) -> option.value is visibility)[0]
                    $scope.selectedChannel = STORY_CHANNEL_OPTIONS.filter((option) -> option.value is channel)[0]
                    $scope.selectedStatus = STORY_STATUS_OPTIONS.filter((option) -> option.value is status)[0]
                    $scope.showCopyDialog = false
                    onSuccess = (response) ->
                        { snapshot_pointers, publish_from } = response
                        originalStatus = snapshot_pointers.web.approved
                        isAfter = moment(publish_from).isAfter(moment.utc())
                        if originalStatus and isAfter is false
                            $("#web-link").removeClass("non-clickable")
                            $("#web-link > i").removeClass("fa-chain-broken")
                            $("#web-link > i").addClass("fa-link")
                            $("#web-link").addClass("highlight")
                            $scope.hintText = "Copy published story link"
                            if $scope.clickedSaveButton and $scope.storyNeverPublished
                                if $scope.showSocialEngegment
                                    $scope.copyLink()
                                $scope.clickedSaveButton = false
                                $scope.storyNeverPublished = false
                        else
                            $("#web-link > i").removeClass("fa-link")
                            $("#web-link").removeClass("highlight")
                            $("#web-link").addClass("non-clickable")
                            $("#web-link > i").addClass("fa-chain-broken")
                            $scope.hintText = "Can't copy link for unpublished story"
                            if !originalStatus or isAfter
                                $scope.clickedSaveButton = false
                                $scope.storyNeverPublished = true
                    onError = ->
                        NotificationService.error "An error has occurred trying to load this story."
                    Story.load($state.params.story).then onSuccess, onError
                else
                    # reset for new story
                    $scope.selectedVisibility = null
                    $scope.selectedChannel = null
                    $scope.selectedStatus = null

            storypad = $scope.storypad

            $scope.$watch 'storyinfo.elements', (newValue, oldValue) ->
                if newValue isnt oldValue
                    storypad.sendMessage StorypadMessageType.TOGGLE_UI, element:'elements', active:StoryInfo.elements

            $scope.$watch 'storyinfo.images', (newValue, oldValue) ->
                if newValue isnt oldValue
                    storypad.sendMessage StorypadMessageType.TOGGLE_UI, element:'images', active:StoryInfo.images

            $scope.$watch 'storyinfo.settings', (newValue, oldValue) ->
                if newValue isnt oldValue
                    storypad.sendMessage StorypadMessageType.TOGGLE_UI, element:'settings', active:StoryInfo.settings
                    $scope.settingsEnabled = false
                    $timeout ->
                        $scope.settingsEnabled = true
                    , 1000

            AAPSourceChecker = (org_id) ->
                if AppState.storyPublishState.org_id and org_id not in [OrganizationIds.AAP]
                    false
                else
                    source = AppState.storyPublishState.org_id or org_id
                    source in [OrganizationIds.AAP]

            $scope.saveStory = ->
                if AppState.showBreakingConfimBox is 'published' and BreakingNews.showUpdate
                    storypad.sendMessage StorypadMessageType.UPDATE_PUBLISH_FROM, $scope.storypad.storyModel.publish_from
                    $timeout ->
                        warningMsgAllowanceAr = ''
                        warningMsgCutoverAr = ''
                        if AppState.breakingNews.cutoverAr.length > 0
                            AppState.breakingNews.cutoverAr.map (item) =>
                                if warningMsgCutoverAr.length is 0
                                    warningMsgCutoverAr+='"'+item.name+'"'
                                else
                                    warningMsgCutoverAr+=', "'+item.name+'"'

                        if AppState.breakingNews.allowanceAr.length > 0
                            AppState.breakingNews.allowanceAr.map (item) =>
                                if warningMsgAllowanceAr.length is 0
                                    warningMsgAllowanceAr+='"'+item.name+'"'
                                else
                                    warningMsgAllowanceAr+=', "'+item.name+'"'
                        $scope.finalBreakingNewsNotification(warningMsgAllowanceAr, warningMsgCutoverAr)
                    , 500
                else
                    if $scope.storyModel? and AAPSourceChecker($scope.storyModel.organization)
                        NotificationService.confirm("This article is sourced from AAP. Please be aware that by editing it,
                         you will halt further updates on our websites. This action could place us at a competitive disadvantage.
                         Could you please confirm if you wish to proceed with the edits?"
                        ).then -> $rootScope.$broadcast(StoryAssistanceRequestType.ACTIVATE_STORY_ASSISTANCE)
                    else
                        $rootScope.$broadcast(StoryAssistanceRequestType.ACTIVATE_STORY_ASSISTANCE)

            $scope.$on StoryAssistanceMessageType.SAVE_STORY, ->
                $scope.clickedSaveButton = true
                storypad.sendMessage StorypadMessageType.SAVE, $scope.storypad.storyModel

            $scope.$on StoryAssistanceMessageType.DISPLAYING_STORY_ASSISTANCE, (_, status) ->
                $scope.displayingStoryAssistance = status

            $scope.finalBreakingNewsNotification = (a,b) ->
                if b.length > 0
                    mastheadsExcluded = "Due to daily allowance, unfortunately the emaill will not be sent to #{b}."
                else mastheadsExcluded=""
                if a.length > 0
                    mastheadsincluded = "Are you sure you would like to send Breaking News to all Subscribers of #{a}?"
                else mastheadsincluded = ""
                if a.length is 0
                    notify = NotificationService.confirmWithNotSendingBreakingNews
                else
                    notify = NotificationService.confirmWithSendingBreakingNews
                notify(mastheadsincluded + " " + mastheadsExcluded).then (response)->
                        if !AppState.breakingNews.story_id or !AppState.breakingNews.snapshot_id or !AppState.breakingNews.email_subject or !AppState.breakingNews.template_type or !AppState.breakingNews.siteIds.length
                            NotificationService.create 'note', "Please complete form", "Breaking news form is not completed"
                        else
                            if response.type is 'second'
                                if !AppState.breakingnewsDateValidation
                                    NotificationService.create 'error', 'Invalid publish date: ', 'Breaking News email cannot be sent as the story is scheduled to publish in future date.', 0
                                else
                                    $rootScope.$broadcast(StoryAssistanceRequestType.ACTIVATE_STORY_ASSISTANCE)

                                    $scope.getStorySavedResults = ->
                                        AppState.storySaved
                                    $scope.$watch 'getStorySavedResults()', (nv, ov) ->
                                        if nv isnt ov and nv is true and response.type is 'second'
                                            $scope.sendingBreakingNewsEmailToSuzuka = true
                                            email = AppState.breakingNews.email_subject
                                            if AppState.breakingNews.allowanceAr.length == 0
                                                return
                                            data =
                                                sites: AppState.breakingNews.allowanceAr.map (site) => site.id
                                                story_id: AppState.breakingNews.story_id
                                                snapshot_id: AppState.breakingNews.snapshot_id
                                                email_subject: email
                                                template_type: AppState.breakingNews.template_type
                                            BreakingNews.sendBreakingNewsEmailToSuzuka(data).then (res) ->
                                                NotificationService.create 'success', "Breaking News successfully sent out", '', 5000
                                                $scope.sendingBreakingNewsEmailToSuzuka = false
                                            .catch ->
                                                $scope.sendingBreakingNewsEmailToSuzuka = false
                                                NotificationService.create 'error', 'Server Error:', 'Unable to send Breaking News at the moment, try again later.', 0
                                                window.Raven.captureException 'Breaking news error - Unable to send Breaking News at the moment'
                            else
                                $rootScope.$broadcast(StoryAssistanceRequestType.ACTIVATE_STORY_ASSISTANCE)

            $scope.shareWithFb = ->
                window.open("https://www.facebook.com/sharer/sharer.php?u=#{$("#StoryLink").val()}")
                return

            $scope.shareWithTw = ->
                window.open("http://twitter.com/share?url=#{$("#StoryLink").val()}&text=#{encodeURI($scope.storyModel.title)}")
                return

            $scope.shareWithEmail = ->
                window.open("mailto:?subject=#{encodeURI($scope.storyModel.title)}&body=I%20would%20like%20to%20share%20something%20with%20you%0A%0D#{encodeURI($("#StoryLink").val())}")
                return

            $scope.cloneStory = ->
                performClone = ()->
                        story_id = $state.params.story
                        if story_id?
                            $scope.storyIsCloning = true
                            Story.clone(story_id).then (clonedStory) ->
                                NotificationService.success "You are now working on a copy of the original story '#{clonedStory.title}'."
                                $state.go 'app.stories.story', story:clonedStory.id
                                $scope.storyIsCloning = false
                            , (errorMsg) ->
                                NotificationService.error errorMsg
                        else
                            NotificationService.error "Unsaved stories can't be cloned."

                if $scope.storyModel? and AAPSourceChecker($scope.storyModel.organization)
                        NotificationService.confirm("This article is sourced from AAP. Please be aware that by copying it,
                         this cloned story will not get updates from AAP. Could you please confirm if you wish to proceed with the cloning?"
                        ).then -> performClone()
                else
                    NotificationService.confirm("Are you sure you want to clone this story?").then ->
                        performClone()

            $scope.copyLink = ->
                if $scope.showCopyDialog
                    $scope.showCopyDialog = false
                else
                    story_id = $state.params.story
                    if story_id?
                        onPreviewSuccess = (response) ->
                            {siteFound, url, domain} = response
                            if siteFound
                                onSuccess = (response) ->
                                    strippedUrl = response.url.replace(/\?.*$/g,"")
                                    $("#StoryLink").val(strippedUrl)
                                    $scope.showCopyDialog = true
                                onError = ->
                                    NotificationService.error 'An error has occurred trying to copy the story link.'
                                Story.loadStoryUrl(story_id, domain).then onSuccess, onError
                            else
                                NotificationService.error 'Website is not found.'
                        onPreviewError = ->
                            NotificationService.error 'An error has occurred trying to copy the story link.'
                        Story.previewURL(story_id).then onPreviewSuccess, onPreviewError
                    else
                        NotificationService.error "Unsaved stories can't be copied. Please save your story and try again."

            $scope.copyLinkToClipboard = ->
                copyText = document.getElementById("StoryLink")
                copyText.select()
                document.execCommand("copy")
                NotificationService.success "Link copied"
                $scope.showCopyDialog = false

            $scope.previewStory = ->
                story_id = $state.params.story
                if story_id?
                    onPreviewSuccess = (response) ->
                        {siteFound, url} = response
                        if siteFound
                            time = new Date().getTime()
                            $window.open url + "&time="+time
                        else
                            msg = 'No site was found for this organization, would you like to preview using the test site?'
                            NotificationService.confirm(msg).then ->
                                $window.open url
                    onPreviewError = ->
                        NotificationService.error 'An error has occurred trying to preview this story.'
                    Story.previewURL(story_id).then onPreviewSuccess, onPreviewError
                else
                    NotificationService.error "Unsaved stories can't be previewed. Please save your story and try again."

            $scope.storyLayout = ->
                story_id = $state.params.story
                if story_id?
                    onPreviewSuccess = (response) ->
                        {siteFound, url} = response
                        secureUrl = url.replace('http://','https://');
                        if siteFound
                            time = new Date().getTime()
                            $("#story-editor-container").addClass("flexlayout");
                            $(".hide-element").hide();
                            $("#layuout-tool-bar").show();
                            $("#story-layout").show();
                            $("#mobile-layout-header").show();
                            $("#story-layout").removeClass();
                            $("#story-layout").addClass("mobile");
                            $("#mobile-layout").addClass("white");
                            $("#nn-editor-container").addClass("hide-left-bar");
                            $("#tablet-layout").removeClass("white");
                            $("#desktop-layout").removeClass("white");
                            $("#story-layout-iframe").attr('src', secureUrl + "&time="+time);
                        else
                            msg = 'No site was found for this organization, would you like to preview using the test site?'
                            NotificationService.confirm(msg).then ->
                                $("#story-editor-container").addClass("flexlayout");
                                $(".hide-element").hide();
                                $(".preview-mode-element").show();
                                $("#story-layout").removeClass();
                                $("#story-layout").addClass("large-screen");
                                $("#story-layout-iframe").attr('src', secureUrl)
                    onPreviewError = ->
                        NotificationService.error 'An error has occurred trying to preview this story.'
                    Story.previewURL(story_id).then onPreviewSuccess, onPreviewError
                else
                    NotificationService.error "Unsaved stories can't be previewed. Please save your story and try again."


    .directive 'nnToggleStoryElements', (StoryInfo, nnSidenavService) ->
        restrict: 'A'
        link: (scope, element, attrs) ->
            element.on 'click', ->
                StoryInfo.toggleElements()

    .directive 'nnToggleStoryMedia', (StoryInfo, nnSidenavService) ->
        restrict: 'A'
        link: (scope, element, attrs) ->
            element.on 'click', ->
                StoryInfo.toggleMedia().then (mediaActive) ->
                    if mediaActive
                        nnSidenavService('mside').close()
                        nnSidenavService('storyinfo').close()

    .directive 'nnOpenStorySettings', (StoryInfo, nnSidenavService) ->
        restrict: 'A'
        link: (scope, element, attrs) ->
            element.on 'click', ->
                StoryInfo.toggleSettings(true).then (settingsActive) ->
                    if settingsActive
                        nnSidenavService('mside').close()
                        nnSidenavService('storyinfo').close()

    .directive 'nnToggleStorySettings', (StoryInfo, nnSidenavService) ->
        restrict: 'A'
        link: (scope, element, attrs) ->
            element.on 'click', ->
                StoryInfo.toggleSettings().then (settingsActive) ->
                    if settingsActive
                        nnSidenavService('mside').close()
                        nnSidenavService('storyinfo').close()


    .directive 'nnToggleStoryInfo', ($timeout, StoryInfo, nnSidenavService) ->
        restrict: 'A'
        link: (scope, element, attrs) ->
            element.on 'click', ->
                scope.$apply ->
                    nnSidenavService('storyinfo').toggle().then (storyInfoActive) ->
                        if storyInfoActive
                            StoryInfo.hide()
                            if scope.task?.content_object?.id?
                                nnSidenavService('mside').open()
                        else
                            nnSidenavService('mside').close()
