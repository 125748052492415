'use strict'

angular.module 'nn.page-thumb-editor.directives', []

  .directive 'nnAdvertHole', ->
    restrict: 'A'
    scope: { advert: '=', hole: '=', id: '=', offset: '=' }
    templateNamespace: 'svg'
    templateUrl: '/templates/advert-hole.html'

  .directive 'nnPageBuilderGrid', ($location) ->
    restrict: 'EA'
    templateNamespace: 'svg'
    template: '''
      <defs>
        <pattern id="column" x="0" y="0" ng-attr-height="{{height}}" ng-attr-width="{{column.width}}" patternUnits="userSpaceOnUse">
          <line x1="0" y1="0" x2="0" ng-attr-y2="{{height}}" stroke="#cccccc" stroke-width="1"/>
          <line ng-attr-x1="{{column.width - 5}}" y1="0" ng-attr-x2="{{column.width - 5}}" ng-attr-y2="{{height}}" stroke="#cccccc" stroke-width="0.5"/>
        </pattern>

        <pattern id="row" x="0" y="0" ng-attr-height="{{row.height}}" ng-attr-width="{{width + 5}}" patternUnits="userSpaceOnUse">
          <line x1="0" y1="0" ng-attr-x2="{{width + 5}}" y2="0" stroke="#cccccc" stroke-width="1"/>
        </pattern>
      </defs>

      <svg ng-attr-x="{{ margins.x }}" ng-attr-y="{{ margins.y }}" >
        <rect id="grid-column" ng-attr-width="{{width}}" ng-attr-height="{{height}}" fill="url({{url}}#column)" stroke="#cccccc" stroke-width="1"/>
        <rect id="grid-row" ng-attr-width="{{width}}" ng-attr-height="{{height}}" fill="url({{url}}#row)"/>
      </svg>
      '''
    scope: { height: '=', width: '=', margins: '=' }
    controller: ($scope) ->

      $scope.url = $location.absUrl()

      $scope.row =
        height: 21.875

      $scope.column =
        width: 83.333

  .directive 'nnPageActiveEditor', ($timeout, $stateParams, nnSidenavService, PageExplorer, Explorer) ->
    restrict: 'A'
    controller: ($scope) ->
      deselectAll = ->
        if $scope.schema and angular.isFunction $scope.schema.setActiveHole
          $scope.schema.setActiveHole()

      $scope.$watchCollection 'page', (newPage, oldPage) ->
        if newPage
          if $stateParams.page isnt $scope.currentPage
            if $scope.schema and angular.isFunction($scope.schema.setActiveHole) and $scope.page.layout.elements
              $scope.schema.setActiveHole()  if $scope.currentPage
              $scope.currentPage = $stateParams.page

        else deselectAll()

  .directive 'nnPageThumbEditor', ($timeout, $state, Story, AdonisData, User, $parse, $compile, $stateParams, AppState, nnSidenavService, PageExplorer, Explorer, Runsheet, AdonisService, ModalDialogService, NotificationService) ->
    restrict: 'EA'
    templateNamespace: 'svg'
    templateUrl: (iElem, tAttrs) -> '/templates/page-' + tAttrs.type + '.html'
    scope: { page: '=', side: '@', schema: '=?', meta: '=', showPreview: '=', loading: '=', hybridMode: '=', thumbnail: '@', copyfitStatus: '=', spread: '=' }
    controller: ($scope) ->
      $scope.preview = path: null
      $scope.explorer = PageExplorer
      $scope.offset = x: 0
      $scope.activeDragHole = null
      $scope.assigneeRequested = true
      if $scope.schema
        $scope.schema.activeHole = $scope.page?.layout?.elements?.story[$scope.schema.activeHoleId]

      $scope.holeClick = (hole, type, holeid, override) ->
        $scope.schema.active.side = $scope.side
        if AppState?.page?.active?.newslistEnabled?.newslist and type is 'story' and hole.task == null
          $scope.schema.setActiveHole 'runsheet', 'runsheet'
        else
          $scope.schema.setActiveHole hole, type, holeid, override

      $scope.copyfitTasksEnabled = false
      
      $scope.dropCallback = (event, item, hole, type) =>
        checkTaskLinkedAndAssign = =>
          onStoryResolved = (story) ->

            onExternalStoryOrMultipleTasks = (story, pub) =>
              if $scope.copyfitTasksEnabled
                childScope = $scope.$new()
                childScope.story = story
                childScope.pub = pub
                childScope.selected = { value: null }
                childScope.organisationId = pub.organisations[0]
                childScope.onCancel = =>
                  ModalDialogService.close()
                childScope.onCreate = =>
                  Runsheet.createCopyfitTask(item.runsheetIdeaId, item.taskId, childScope.selected.value.id)
                    .then (response) =>
                      console.log("Response received", response)
                      ModalDialogService.close()
                      action(response.data.createCopyfitTask.runsheetIdea.node.alternativeTaskId)

                content = $compile('''
                      <h1>Select assignee for a new task</h1>

                      <nn-user-select selected="selected" organisationId="organisationId"></nn-user-select>

                      <hr/>

                      <ul class="button-group">
                          <li><button ng-click="onCreate()" class="button success">Create</a></li>
                          <li><button ng-click="onCancel()" class="button">Cancel</a></li>
                      </ul>
                    '''
                )(childScope)

                ModalDialogService.open(content)
              else
                NotificationService.error("This idea is shared across multiple Newsrooms and cannot be placed until a Story has been created by the Source Organisation.")

            isAssigned = (item) =>
              if ($scope.copyfitTasksEnabled)
                Runsheet.holes(item.id).find (hole) ->
                  hole.layoutId.substring(0, hole.layoutId.indexOf("-")) == $scope.page.details.pub
              else
                !!Runsheet.holes(item.id).length

            action = (taskId, organisationId) =>
              assignTask = =>
                hole.assignId taskId

              checkCurrentStoryHole = (callback) =>
                if hole.task?
                  hole.clear('Are you sure you want to replace the existing task?', $scope.schema).then ->
                    callback()
                else
                  callback()

              checkDependentStoryHoles = (callback) =>
                if isAssigned(item)
                #If the idea is already assigned to a hole(s) we unlink
                  NotificationService.confirm("This task will be removed from all existing holes. Do you want to proceed?").then =>
                    Runsheet.holes(item.id).forEach ({holeId, layoutId}) ->
                      AdonisService.clearHole holeId, layoutId
                    callback()
                else
                  callback()

              checkCurrentStoryHole =>
                checkDependentStoryHoles =>
                  assignTask()

            AdonisData.getPublicationPresenter($scope.schema.issue.pub).then( (pub) ->
              taskOrganisationId = parseInt(window.atob(item.task.organisation.id).split(":")[1], 10)
              isExternalStory = story && !pub.organisations.includes(parseInt(story.organization))
              hasCopyfitTaskAlready = !!item.alternativeTaskId
              isExternalTask = pub.organisations.indexOf(taskOrganisationId) == -1

              if (isExternalStory || (!story && isExternalTask))
                if (hasCopyfitTaskAlready)
                  action(item.alternativeTaskId, taskOrganisationId)
                else
                  onExternalStoryOrMultipleTasks(story, pub)
              else
                action(item.taskId, taskOrganisationId)
            )


          if item.storyId
            Story.load(item.storyId)
              .then (data) => onStoryResolved(data)
          else
            onStoryResolved(null)

        checkTaskLinkedAndAssign()

      $scope.dragleaveCallback = (event, item, hole, type) =>
        $scope.activeDragHole = null if $scope.activeDragHole

      $scope.dragoverCallback = (event, item, hole, type) =>
        if $scope.activeDragHole?.holeId != hole.holeId
          $scope.activeDragHole = hole
          $scope.$apply()

      $scope.pageClick = (page) ->
        if page.page isnt $stateParams.page
          $state.go 'app.pages.issue.page', book: $stateParams.book, page: page.page, date: $stateParams.date, pub: $stateParams.pub
        if page.page then PageExplorer.showTemplates() else PageExplorer.showPages()

      $scope.notExcluded = (type) ->
        type not in ['subheading', 'byline', 'topic', 'location']

      deselectAll = ->
        if $scope.schema and angular.isFunction $scope.schema.setActiveHole
          $scope.schema.setActiveHole()

      $scope.parsePageNumber = (page) ->
        parseInt page

      buildPageElements = (nv, ov) ->
        if nv
          if $scope.schema?.active?.number
            if $scope.parsePageNumber($scope.schema.active.number) is $scope.page.page
              if $scope.schema and angular.isFunction($scope.schema.setActiveHole) and $scope.page.layout.elements
                if $scope.schema.activeHoleType and $scope.schema.activeHoleId
                  newHoleData = $scope.page.layout.elements[$scope.schema.activeHoleType][$scope.schema.activeHoleId]
                  if newHoleData
                    $scope.schema.setActiveHole newHoleData, $scope.schema.activeHoleType, $scope.schema.activeHoleId, newHoleData.override, true
                  else deselectAll()
        else
          $scope.page?.layout = {}

      checkIfOffsetNeeded = ->
        if $scope.spread
          if $scope.spread.is_spread
            if $scope.page.page % 2
              $scope.offset = x: -($scope.page.width + $scope.page.margins.inside + $scope.page.margins.outside)
          else $scope.offset = x: 0

      checkIfOffsetNeeded()

      $scope.$watch 'spread.is_spread', checkIfOffsetNeeded
      $scope.$watch 'page.layout', buildPageElements

      $scope.$watchCollection 'page', (newPage, oldPage) ->
        if newPage and newPage.page
          $scope.pageSide = ["left", "right"][newPage.page % 2]

          if newPage.margins
            $scope.margins = y: newPage.margins.top

            if $scope.side is 'left'
              $scope.margins.x = newPage.margins.outside
            else
              $scope.margins.x = newPage.margins.inside

            $scope.vBox = '0 0 ' + (newPage.width + newPage.margins.inside + newPage.margins.outside) + ' ' + (newPage.height + newPage.margins.top + newPage.margins.bottom)

          if newPage.layout.template isnt oldPage?.layout.template
            deselectAll()
        else deselectAll()

      $scope.$on '$destroy', -> deselectAll()

  .directive 'nnStoryHole', (StoryFitPresenter, copyfitWebWorker, $timeout) ->
    restrict: 'EA'
    templateNamespace: 'svg'
    template: '''
      <svg-path style="cursor: pointer" ng-if="storyHole" elements="storyHole.elements" offset="hole"></svg-path>

      <g ng-if="copyfit" class="copyfit">
        <path ng-attr-d="{{copyfit.lines.path}}" style="cursor: pointer" ng-attr-fill="{{copyfit.lines.colour}}"></path>
        <path ng-attr-d="{{copyfit.underset.path}}" style="cursor: pointer" ng-attr-fill="{{copyfit.underset.colour}}"></path>
        <path ng-attr-d="{{copyfit.overset.path}}" style="cursor: pointer" ng-attr-fill="{{copyfit.overset.colour}}"></path>
        <g ng-repeat="image in copyfit.images" ng-init="imageHole = storyHole.elements['figures-' + ($index + 1)]; getLength($index)">
          <path style="cursor: pointer" ng-attr-d="{{image.path}}" ng-attr-fill="{{image.colour}}"></path>
          <rect height="20" ng-attr-width="{{ bgWidth[$index] }}" ng-attr-x="{{imageHole.x + hole.x }}" ng-attr-y="{{imageHole.y + hole.y + 20}}" fill="white"></rect>
          <text ng-attr-x="{{imageHole.x + hole.x + 5.5}}" font-size="8pt" ng-attr-y="{{imageHole.y + hole.y + 34}}">{{ image.validation.width }}px (w) x {{ image.validation.height }}px (h)</text>
        /g>
      </g>
    '''
    scope: { id: '=', hole: '=', preview: '=', storyHole: '=', tasksNotValid: '=' }
    link: (scope, element) ->
        scope.bgWidth = []

        doUnsubscribe = ->
          StoryFitPresenter.deleteSubscription scope.id

        scope.tasksNotValid = {}
        excludedList = ['subheading', 'byline', 'topic', 'location']

        scope.getLength = (index) ->
          width = element?.children()?[1]?.children?[(index + 3)]?.children?[2]?.clientWidth
          if width?
            $timeout ->
              scope.bgWidth[index] = width

        pushItem = (item, type = null, subtype = null) ->
          if angular.isObject item and item.info
            task = scope.hole.task
            status = item.info.v.status.t
            if status is 'Overset'
              scope.tasksNotValid[task].elements ?= []
              if subtype
                scope.tasksNotValid[task].elements[type]?.push item: subtype, status: status
              else
                scope.tasksNotValid[task].elements.push item: type, status: status

        copyfitSubscription = (id) ->
          doUnsubscribe()
          StoryFitPresenter.getSubscription(id).then (holes) ->
            scope.holes = holes

        scope.$watchCollection 'holes', (nv, ov) ->
          if nv
            scope.preview = nv.preview
            scope.storyHole = nv.storyHole
            if nv.copyfitHole isnt null and scope.preview
              offset = x: scope.hole.x, y: scope.hole.y
              copyfitWebWorker.run('buildPath', [nv.copyfitHole, offset]).then (data) ->
                scope.copyfit = data

                if scope.tasksNotValid?[scope.hole.task]
                  delete scope.tasksNotValid[scope.hole.task]

                scope.tasksNotValid?[scope.hole.task] = publishable: nv.isPublishable

                angular.forEach nv.copyfitHole, (item, key) ->
                  if angular.isObject item and item.info
                    if excludedList.indexOf(key) is -1
                      pushItem item, key
                  else if angular.isArray(item) and key is 'figures'
                    for figures in item
                      pushItem figures.image, key, 'image'
                      pushItem figures.caption, key, 'caption'
            else 
              scope.copyfit = null

        scope.$watch 'id', (nv, ov) ->
          scope.holes = null

          if nv isnt ov
            copyfitSubscription nv

        if scope.id
          copyfitSubscription scope.id

        scope.$on '$destroy', ->
          scope.preview = null
          scope.tasksNotValid = undefined
          doUnsubscribe()

  .directive 'svgLeftHeading', ($filter, $timeout, $q) ->
    restrict: 'A'
    scope: { hole: '=', heading: '=', offset: '=?' }
    templateNamespace: 'svg'
    template: '''
      <rect height="20" ng-attr-width="{{width}}" ng-attr-x="{{hole.x + offset.x}}" ng-attr-y="{{hole.y + offset.y}}" ng-attr-fill="{{bgColor}}"></rect>
      <text ng-attr-x="{{hole.x + offset.x + 5.5}}" font-size="8pt" ng-attr-y="{{hole.y + offset.y + 13}}">{{ text | titleCase }} {{ id }}</text>
      '''
    link: (scope, element, attrs) ->
      isMulti = /[A-z]/g

      scope.$watch 'heading', (nv, ov) ->
        if nv isnt ov or not scope.width
          scope.bgColor = 'white'
          heading = nv.replace(/([A-Z])/g, " $1")

          [ scope.text, scope.id ] = heading.split '-'

          if nv
            getLength(1).then (data) ->
              scope.width = 75

      getLength = (index) ->
        $timeout ->
          element.children()[index].clientWidth

  .directive 'svgRightHeading', ($filter, $timeout, $q) ->
    restrict: 'A'
    scope: { hole: '=', heading: '=', offset: '=?' }
    templateNamespace: 'svg'
    template: '''
      <rect ng-show="heading" height="20" ng-attr-width="{{width + 12 }}" ng-attr-x="{{hole.x + hole.width - width - 12}}" ng-attr-y="{{ hole.y + offset.y }}"></rect>
      <text fill="white" font-color="white" ng-attr-x="{{hole.x + hole.width - width - 6}}" ng-attr-y="{{ hole.y + offset.y + 13 }}" font-size="8pt">{{ text }}</text>
      '''
    link: (scope, element, attrs) ->

      scope.$watch 'heading', (nv, ov) ->
        if nv isnt ov or not scope.width
          heading = nv

          if nv.split(' ')[1]
            heading = $filter('titleCase')(nv)

          scope.text = heading
          if nv
            scope.width = 75

      getLength = (index) ->
        $timeout -> element.children()[index].clientWidth
