'use strict'

angular.module 'adonis.issue.collections.pagelayouts.elements.stories', []

.factory 'pageLayoutStories', (BasePresenter, elementsParser, TaskService, NotificationService, AppState) ->
  class pageLayoutStories extends BasePresenter
    constructor: ($queryStream, element, holeId) ->
      super $queryStream

      @sync element, holeId

    sync: (elem, holeId) ->
      @holeId = holeId

      if elem.story?.id
        @task = parseInt elem.story?.id?.split('-')[1]
      else @task = null

      @elements = elem.template.id

      angular.extend @, elementsParser.createHole elem.bounds

    assignId: (id) ->
      @query 'assign' + @getCommandType() + 'StoryTask', @holeId, parseInt(id)

    clear: (message, schema)  ->
      NotificationService.confirm(message || 'Are you sure you want to remove this task?').then =>
        @query 'clear' + @getCommandType() + 'StoryTask', @holeId, @task
        if AppState?.page?.active?.newslistEnabled?.newslist
          schema.setActiveHole 'runsheet', 'runsheet'