'use strict'

angular.module 'adonis.issue', [
  'adonis.issue.collections.spreads'
  'adonis.issue.collections.pagelayouts'
  'adonis.issue.collections.spreadlayouts'
  'adonis.issue.templates.advertholestemplates'
]

.factory 'IssuePresenter', ($timeout, $q, AdonisData, BasePresenter, AdonisService, $rootScope) ->
  class IssuePresenter extends BasePresenter
    constructor: ($queryStream, initData) ->
      super $queryStream, initData

      @_presenter = 'IssuePresenter'
      @issuesMap = []

    findIndex: (book, pageNumber) ->
      bookLength = (book.inside.length * 2) + 2
      getLeftRight = (if (pageNumber % 2) then 'right' else 'left')
      spreadOrderIndex = Math.floor(((pageNumber / 2) - 1)).toString()
      firstOrLastPage = pageNumber is 1 or bookLength is pageNumber

      if !firstOrLastPage
        spread: book['inside'][spreadOrderIndex]
        bookLength: bookLength
        active: getLeftRight
      else
        spread:
          info: book['front'].info
          pages:
            left: book['back'].page
            right: book['front'].page
        bookLength: bookLength
        active: getLeftRight

    getPageSubscriptionByTaskId: (id) ->
      defer = $q.defer()

      getSubscription = (data) =>
        { spreadId, side } = data
        [ pub, year, month, day, book ] = spreadId.split '-'

        @getSubscription("#{pub}-#{year}-#{month}-#{day}").then (books) =>
          currentBook = books.books[book]
          { spread } = @findIndex currentBook, data.page

          defer.resolve
            schema:
              activeHoleId: data.holeId
              activeHoleType: 'story'
            active:
              number: data.page
              book: book
              spread: spread.info
              side: side
            issue:
              pub: pub
              date: "#{year}-#{month}-#{day}"
            data: books
            spread: spread

      taskError = -> defer.resolve active: null, issue: null, data: null

      AdonisData.getStoryIssuesPresenter(id).then getSubscription, taskError

      defer.promise

    getSubscription: (id) ->
      if not @issuesMap.hasOwnProperty id
        @issuesMap[id] ?= data: {}, subs: 0, defer: $q.defer()

        if not @issuesMap[id].data.books?
          @subscribe @_presenter, id, (data) =>
            @issuesMap[id].data.meta =
              spreadLayoutTemplates: data.spreadLayoutTemplates

            spreadKeys = Object.keys data.spreadLayouts
            spreadData = spreadKeys.reduce((acc, key) ->
              acc[key] = data.spreadLayouts[key]
              acc[key].leftPage = data.spreads[key].leftPage
              acc[key].rightPage = data.spreads[key].rightPage
              acc
            , {})

            $q.all
              advertHoleTemplates: @getTemplate 'advertHoleTemplates', data.adverts
              pageLayouts: @getRepo 'pageLayouts', data.pageLayouts
              spreadLayouts: @getRepo 'spreadLayouts', spreadData
            .then (meta) =>
              if not @issuesMap[id].data.books?
                [pub, year, month, day] = id.split '-'
                @issuesMap[id].data.info =
                  formattedDate: "#{day}/#{month}/#{year}"
                  pubname: data.publicationName
                @issuesMap[id].data.books = @getRepo 'spreads', data.spreads, meta
                @issuesMap[id].defer.resolve @issuesMap[id].data
              else @issuesMap[id].data.books.update data.spreads, meta

      @issuesMap[id].subs++
      @issuesMap[id].defer.promise

    deleteSubscription: (id) ->
      if @issuesMap.hasOwnProperty id
        @issuesMap[id].subs--

        if @issuesMap[id].subs is 0
          # give 2 seconds to see if still required
          # on next route change pb -> storypad
          $timeout =>
            if @issuesMap[id]?.subs is 0
              @unsubscribe @_presenter, id
              delete @issuesMap[id]
          , 2000, false # dont trigger digest

      @

  new IssuePresenter AdonisService.$queryStream
