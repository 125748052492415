'use strict'

angular.module 'nn.page-explorer.directives', []

  .directive 'nnPageExplorer', (PageWorkFlows) ->
    templateUrl: '/templates/page-explorer.html'
    restrict: 'E'
    scope: {}
    controller: ($scope, $state, Explorer, AppState, $location, PageExplorerSections, PageExplorer, $filter, moment, AdonisService, FeatureAccess) ->
      $scope.editorialspace = undefined
      $scope.issue = moment()
      $scope.sections = PageExplorerSections
      $scope.explorerState = PageExplorer
      $scope.viewAll = value: false, hybrid: false
      $scope.state = AppState.page
      $scope.explorer = Explorer

      $scope.pageStatusFilter = {}
      $scope.page = data: {}
      $scope.pages = data: {}
      $scope.issue = {}
      $scope.days = []
      $scope.issuePickerDate = moment().add(1, 'days')
      $scope.issueDate = moment().add(1, 'days').format('YYYY-MM-DD')
      $scope.selection =
        pub: null
        issue: null

      if $scope.state.issue?.pub and $scope.state.issue?.date
        $scope.issue = date: $filter('date')($scope.state.issue.date, 'yyyy-MM-dd'), pub: $scope.state.issue.pub
        $scope.issuename = $scope.state.issue.pub + ' ' + $filter('date')($scope.state.issue.date, 'dd-MM-yyyy')

        # [year, month, day] = books.issue.date.split '-'
        # $scope.page.issueDate = "#{day}/#{month}/#{year}"
        # $scope.page.pubName = books.pubName

      $scope.workflows = angular.copy PageWorkFlows
      # $scope.workflows.PUBLISHED = { name: "Published", code: true }

      $scope.selectSection = (section) ->
          PageExplorer.menuItems = true
          PageExplorer.menuItemSelected = PageExplorerSections.indexOf section

      $scope.selectIssue = (issue, pub) ->
          issue = new Date issue
          $scope.issue =  date: $filter('date')(issue, 'yyyy-MM-dd'), pub:pub
          $state.go 'app.pages.issue', date: $filter('date')(issue, 'yyyy-MM-dd'), pub:pub
          $scope.issuename = pub+' '+$filter('date')(issue, 'dd-MM-yyyy')
          PageExplorer.showPages()

      $scope.handleMonthChange = (date) ->
        $scope.issuePickerDate = date

      $scope.newsnowEnabled = false

      $scope.$watch 'selection', (nv, ov) ->
        pubCode = nv?.pub?.code
        if pubCode? and pubCode isnt ov?.pub?.code
          $scope.selectedPubCode = nv.pub.code
          FeatureAccess.setCurrentOrganization($scope.selectedPubCode).then (newsnowEnabled) ->
            $scope.newsnowEnabled = newsnowEnabled
      , true 

      isAfter = (subject) ->
        [sYear, sMonth, sDay] = subject.split('-')
        sDay = Number.parseInt(sDay)
        (candidate) ->
          [cYear, cMonth, cDay] = candidate.split('-')
          (sYear == cYear && sMonth == cMonth && sDay < Number.parseInt(cDay)) or 
            (cMonth > sMonth and cYear >= sYear) or (cYear > sYear)

      $scope.$watchGroup ['selectedPubCode', 'issuePickerDate'], (newVals, oldVals) ->
        [pub, date] = newVals
        if pub? and date?     
          month = date.month()+1
          month = if month > 9 then '' + month else '0' + month
          year = date.year()
          issue = "#{pub}-#{year}-#{month}" 
          if $scope.selection.issue? and $scope.selection.issue isnt issue
            AdonisService.unsubscribe 'PubYearMonthIssuesPresenter', $scope.selection.issue
          isAfterToday = isAfter(moment().format('YYYY-MM-DD'))  
          AdonisService.subscribe ['PubYearMonthIssuesPresenter', issue], ({issues}) ->
            $scope.days = Object.keys(issues)
            .map((day) -> issues[day].id.substring(issues[day].id.indexOf('-')+1))
            .filter(isAfterToday)
          $scope.selection.issue = issue

      $scope.$watch 'days', (newVal, oldVal) ->
        if newVal
          $scope.highlightDay = (date) ->
            date.format('YYYY-MM-DD') in $scope.days

      $scope.$on '$destroy', ->
        if $scope.selection.issue?
          AdonisService.unsubscribe 'PubYearMonthIssuesPresenter', $scope.selection.issue

      $scope.togglePreview = ->
        if $scope.viewAll.value and not $scope.viewAll.hybrid
          $scope.viewAll.hybrid = true
        else
          $scope.viewAll.hybrid = false
          $scope.viewAll.value = !$scope.viewAll.value

      $scope.back = ->
        if PageExplorer.selectTemplates
            PageExplorer.showPages()
        else PageExplorer.showHome()
