'use strict'

angular.module 'adonis.storyfit', []

.factory 'storyHoleTemplate', (adonisUtils) ->
  (data) ->
    objects = {}
    x1 = 0; y1 = 0; x2 = 0; y2 = 0;

    checkBoundingBox = ({topLeft, bottomRight}) ->
      x1 = Math.min x1, topLeft.x.value
      y1 = Math.min y1, topLeft.y.value

      x2 = Math.max x2, bottomRight.x.value
      y2 = Math.max y2, bottomRight.y.value

    angular.forEach data, (item, key) ->
      if angular.isArray item
        for el, v of item
          id = key + '-' + (parseInt(el) + 1)

          objects[id] = adonisUtils.unpackBoundingBox v.boundingBox
          checkBoundingBox v.boundingBox

          if key is 'figures'
            objects[id].elements =
              image: adonisUtils.unpackBoundingBox v.image.boundingBox
              caption: adonisUtils.unpackBoundingBox(v.caption.boundingBox) if v.caption?.boundingBox

      else if item
        objects[key] = adonisUtils.unpackBoundingBox item.boundingBox
        checkBoundingBox item.boundingBox

    x: x1
    y: y1
    height: (y2 - y1)
    width: (x2 - x1)
    elements: objects

.factory 'storyfitRepo', (BasePresenter, $q, storyHoleTemplate) ->
  class storyfitRepo extends BasePresenter
    constructor: ($queryStream, initData) ->
      @sync initData

    sync: (data) ->
      @storyHole = storyHoleTemplate data.storyHole
      @copyfitHole = data.storyHole
      @preview = data.preview
      @isPublishable = data.isPublishable

.factory 'StoryFitPresenter', ($timeout, $q, BasePresenter, AdonisService) ->
  class StoryFitPresenter extends BasePresenter
    constructor: ($queryStream, initData) ->
      super $queryStream, initData

      @_presenter = 'StoryRenderPresenter'
      @storyfitsMap = {}

    getSubscription: (id) ->
      if not @storyfitsMap.hasOwnProperty id
        @storyfitsMap[id] ?= data: null, subs: 0, defer: $q.defer()

        @subscribe @_presenter, id, (data) =>
            if not @storyfitsMap[id].data?
              @storyfitsMap[id].data = @getRepo('storyfit', data)
              @storyfitsMap[id].defer.resolve @storyfitsMap[id].data
            else @storyfitsMap[id].data.sync data

      @storyfitsMap[id].subs++
      @storyfitsMap[id].defer.promise

    deleteSubscription: (id) ->
      if @storyfitsMap.hasOwnProperty id
        @storyfitsMap[id].subs--

        if @storyfitsMap[id].subs is 0
          # give 2 seconds to see if still required
          # on next route change pb -> storypad
          $timeout =>
            if @storyfitsMap[id]?.subs is 0
              @unsubscribe @_presenter, id
              delete @storyfitsMap[id]
          , 2000, false

      @

    close: ->
      super()
      @data = null

  new StoryFitPresenter AdonisService.$queryStream
