 'use strict'

 angular.module 'nn.health-check.directives', []

    .directive 'nnHealthCheck', () ->
        templateUrl: '/templates/health-check.html'
        restrict: 'E'
        scope: {}
        controller: ($scope, NewRelicAPIKey) ->
            $scope.api =
                newrelicAPIKey: NewRelicAPIKey

    .directive 'nnHealthCheckReactApp', (reactDirective) ->
        reactDirective(window.admin.HealthCheck, ['api'])