'use strict'

angular.module 'nn.sentry', []

    .constant 'SENTRY_DSN',
        'https://029dd27d77a9458291e29dfb72c1090d@sentry.io/1215232'

    .value 'SENTRY_OPTIONS',
        whitelistUrls: []
        ignoreUrls: [/javascripts\/storypad\/ckeditor/i]

    .run ($injector, SENTRY_DSN, SENTRY_OPTIONS) ->
        if Raven?
            Raven.config(SENTRY_DSN, SENTRY_OPTIONS).install()
            $injector.get('Auth').user().then (user) ->
                Raven.setUserContext
                    id: user.id
                    email: user.email

    .config ($provide) ->
        if Raven?
            $provide.decorator '$exceptionHandler', ($delegate) ->
                (exception, cause) ->
                    $delegate exception, cause
                    Raven.captureException exception