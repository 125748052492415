'use strict'

angular.module 'nn.task.buttons', []

  .directive 'nnTaskInfoButtons', (taskInfoService, NotificationService, $filter, Workflow) ->
    restrict: 'E'
    templateUrl: '/templates/task-buttons.html'
    link: (scope) ->
      adonisData = null

      onTaskCreateSuccess = (results) ->
        scope.schema.activeHole.assignId results.task.id, scope.schema.active.number, scope.schema.activeHole.activeOverrideHole?

        scope.origTaskData = angular.copy scope.task
        scope.taskInfo.$setPristine()

        if results.workflow?.id
          scope.workflow.resultId = results.workflow?.id

        scope.newTask = false

      onTaskUpdateSuccess = (results) ->
        updateNotification = ->
          NotificationService.success $filter('titleCase')(scope.type) + ' task', 'successfully updated'
          scope.origTaskData = angular.copy scope.task
          scope.taskInfo.$setPristine()

        if scope.type is 'story'
          if not scope.workflow.customWorkflowSelected and scope.origWorkflowData.resultId
            Workflow.removeWorkflowForTask(scope.origWorkflowData).then ->
              updateNotification()
          else if scope.workflow.id isnt scope.origWorkflowData.id and scope.workflow.resultId
            Workflow.updateWorkflowForTask(scope.task.id, scope.workflow).then ->
              updateNotification()
          else if scope.workflow?.id
            Workflow.setWorkflowForTask(scope.task.id, scope.workflow.id).then ->
              Workflow.getWorkflowForTask(scope.task.id).then (data) ->
                scope.workflow.resultId = data[0]?.id
                updateNotification()
          else updateNotification()
        else updateNotification()

      storeAdonisData = ->
        adonisData =
          page: scope.schema.active.number
          type: scope.type
          hole: scope.schema.activeHole
          override: scope.schema.activeHoleOverride

      onTaskCreateError = (data, status) ->
        NotificationService.error $filter('titleCase')(scope.type) + ' task', 'error creating task'

      scope.cancelForm = ->
        scope.taskInfo.$setPristine()
        scope.task = angular.copy scope.origTaskData

        if scope.type is 'story'
          if not scope.task.id
            scope.searchListToggle true, true
        else if scope.type is 'furniture'
          scope.searchListToggle true, true

      scope.createTask = ->
        if not scope.task.assignee
          scope.task.assignee = scope.user.id

        storeAdonisData()

        scope.task.content_object ?= { type: scope.type }

        taskInfoService.createTask(
          scope.task, scope.type, scope.workflow
        ).then onTaskCreateSuccess, onTaskCreateError

      scope.updateForm = ->
        scope.task.content_object ?= { type: scope.type, original_id: null, title: null, id: null }

        taskInfoService.updateTask(
          scope.task, scope.type, scope.origTaskData, scope.workflow, scope.origWorkflowData
        ).then onTaskUpdateSuccess, onTaskCreateError

      scope.checkDisabled = ->
        if scope.type is 'story'
          return scope.taskInfo.$invalid or scope.taskInfo.$pristine
        else if scope.type is 'furniture'
          return not scope.task.content_object?.original_id or scope.taskInfo.$pristine