'use strict'

angular.module 'nn.task.info.services', []

.factory 'taskInfoService', ($q, PageTaskService, TaskService, FurnitureObject) ->

  createTask: (task, type, workflow) ->
    $q (resolve, reject) =>
      if type is 'story'
        TaskService.create(task, type, workflow).then resolve, reject

      if type is 'furniture'
        FurnitureObject.create(task.content_object).success (data) ->
          task.content_object.id = data.id
          TaskService.create(task, type).then resolve, reject

  updateTask: (task, type, origTaskData, workflow, origWorkflowData) ->
    $q (resolve, reject) =>
      if not angular.equals task, origTaskData and not angular.equals workflow, origWorkflowData
        if type is 'story'
            TaskService.update(task.id, task).then resolve, reject
        
        if type is 'furniture'
          FurnitureObject.update origTaskData.content_object.id, task.content_object
          .success -> TaskService.update(task.id, task).then resolve, reject
          .error -> reject()
      
      else reject()
